import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./SettlementsDetail.css";
import Title from "../../../components/title/Title";
import NoItems from "../../../tools/components/v1/widgets/NoItems/NoItems";
import Table from "../../../tools/components/v1/widgets/Table/Table";
import {
  capitalizeMe,
  convertNumberToShortForm,
  getFileType,
  getMeaningfulDate,
  getMyCurrencyInfo,
  getRate,
} from "../../../shared/js/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { toggleImagePreviewAction } from "../../../store/actions/actions";
import { IMAGE_TYPES } from '../../../shared/js/Konstants';

class SettlementsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getRemender = () => {
    let { settlement} = this.props;
    let remender = settlement?.full_amount - settlement?.amount_to_receive;
    return remender;
  };

  convertMoney = (money= 0 )=>{
    let {rates, settlement} = this.props
    let currency = getMyCurrencyInfo(settlement?.opportunity?.enterprise?.currency);
    let finalAmount = money * getRate(currency?.code, rates)
    return finalAmount
  }

  getFileName = (file) => {
    if(!file) return ;
    let arr = file?.split("/");
    let last = arr?.[arr?.length - 1]?.split("?");
    return last[0] ;
  }

  showCommentFile = (file) => {
    if(!file) return ;
    const fileType = getFileType(file);
    if (IMAGE_TYPES.includes(fileType))
      return (
        <img
          src={file}
          alt="  "
          onClick={() => {
            this.props.toggleImagePreview({
              show: true,
              image: file,
            });
          }}
        />
      );

    return (
      <div className="comment-file-container ">
        <div className="elevate-float file-wrapper" onClick={()=>window.open(file, '_blank')}>
          <FontAwesomeIcon icon={faFileAlt} fontSize="7rem" color="maroon" />
        </div>
        <p style={{color:'blue'}} onClick={()=>window.open(file, '_blank')}>{this.getFileName(file)}</p>
      </div>
    );
  };

  showComment = (comment) => {
    return (
      <div className="comment-item-container " style={{ position: "relative" }}>
        <div style={{ display: "flex" }}>
          <div id="commentor-img-container">
            <img
              src={comment?.sender?.image || "https://i.pravatar.cc/300"}
              alt="  "
            />
          </div>
          <div id="commentor-info">
            <h4>{capitalizeMe(comment?.sender?.first_name)}</h4>
            <small>{getMeaningfulDate(comment?.date)}</small>
          </div>
        </div>
        <div id="comment-body">
          <p>{comment?.body || ""}</p>
          <div id="comment-file-container">
            {comment?.attachment
              ? this.showCommentFile(comment?.attachment)
              : null}
          </div>
        </div>
      </div>
    );
  };

  calculateAmountFromPercentage = (percentage, amount=1) => {
    let _amount = (percentage / 100) * amount;
    let final =  _amount;
    return final;
  };

  getPecentage = (pref) => {
    let distribution = pref?.distribution;
    let total = 0;
    let dist;
    if (typeof distribution === "string") {
      distribution = JSON.parse(distribution);
    }
    if (distribution?.distribution === "PERCENTAGE")
      return distribution?.percentage;

    this.props?.prefs?.forEach((election) => {
      if (typeof election.distribution === "string") {
        dist = JSON.parse(election.distribution);
      } else {
        dist = election.distribution;
      }

      if (dist.distribution === "PERCENTAGE") {
        total += Number(dist.percentage);
      }
    });

    return (100 - total).toFixed(2);
  };

  prepareTableData = () => {
    let { settlement } = this.props;
    return this.props?.prefs?.map((item) => {
 let currency = getMyCurrencyInfo(item?.account?.currency);
      return [
        <p>{item?.payment_type}</p>,
        <p>{item?.account?.country}</p>,
        <p>{currency?.code} </p>,
        <p>{item?.account_type}</p>,
        <p>
          {capitalizeMe(
            item?.account_type === "MOBILE"
              ? item?.account?.registered_name
              : item?.account?.bank_name
          )}
        </p>,
        <p>
          *****{" "}
          {item?.account_type === "MOBILE"
            ? item?.account?.phone_number.substring(5, 10)
            : item?.account?.account_number.substring(10, 14)}
        </p>,
        <p>{this.getPecentage(item)} %</p>,
        <p className="i-am-money">
          {convertNumberToShortForm(
            this.convertMoney(
              this.calculateAmountFromPercentage(
                this.getPecentage(item),
                settlement?.amount_to_receive
              )
            )?.toFixed(2)
          )}
        </p>,
      ];
    });
  };

  convertSettlementFigures = () => {
    let { rates, settlement } = this.props;
    let currency = getMyCurrencyInfo(
      settlement?.opportunity?.enterprise?.currency
    );
    return {
      amount: settlement?.amount_to_receive * getRate(currency?.code, rates),
      charges: settlement?.transaction_charges * getRate(currency?.code, rates),
      fullAmount: settlement?.full_amount * getRate(currency?.code, rates),
    };
  };
  render() {
    let { settlement, user } = this.props;
    let currency = getMyCurrencyInfo(user?.currency);
    let figures = this.convertSettlementFigures();
    return (
      <div className="settlement-detail-root">
        <div className="elevate-foat top-card">
          <div id="report-card-logo-and-name-container">
            <div id="settlement-company-det">
              <h2>{settlement?.opportunity?.enterprise?.name}</h2>
              <br />
              <p>{settlement?.opportunity?.name} </p>
            </div>
          </div>

          <div className="total-received-container">
            <p className="total-received-title">To Receive</p>

            <div className="received-money-container">
              <p>{currency?.symbol}</p>
              <h2>{convertNumberToShortForm(figures?.amount?.toFixed(2))}</h2>
            </div>
          </div>

          <div>
            <p className="total-received-title pending">Remaining</p>

            <div className="received-money-container">
              <p>{currency?.symbol}</p>
              <h2>
                {convertNumberToShortForm(
                  this.convertMoney(this.getRemender())?.toFixed(2)
                ) || 0.0}
              </h2>
            </div>
          </div>
          <div>
            <p className="total-received-title">Charges</p>

            <div className="received-money-container">
              <p>{currency?.symbol}</p>
              <h2 style={{ color: "#EC7063" }}>
                {convertNumberToShortForm(figures?.charges?.toFixed(2)) || 0.0}
              </h2>
            </div>
          </div>
        </div>

        <div className="payment-distribution-container">
          <Title>Distribution</Title>
          {this.props?.prefs?.length < 1 ? (
            <NoItems text="Please check add a payment Preference" />
          ) : (
            <>
              <Table
                columns={[
                  "Payment Type",
                  "Country",
                  "Currency",
                  "Account Type",
                  "Account",
                  "Account Number",
                  "percentage",
                  `Amount (${currency?.symbol})`,
                ]}
                data={this.prepareTableData()}
                hoverAnimation={false}
                rootClass="pref-table"
              />
            </>
          )}
        </div>

        <div className="settlement-receipt-container">
          {settlement?.receipt &&   <Title> Settlement Receipt </Title>}
          {settlement?.receipt && (
            <span onClick={() => window.open(settlement?.receipt, "_blank")} style={{color:'blue', cursor:'pointer', marginLeft:'5px'}}>
              View Settlement Receipt
            </span>
          )}
        </div>

        <div className="settlent-comments-container">
          <div className="comments-list-container elevate-foat">
            <Title className="comments-header"> Settlement Updates </Title>
            <div className="divider"></div>
            {settlement && settlement?.comments ? (
              settlement?.comments?.map((comment) => this.showComment(comment))
            ) : (
              <NoItems text="No comments yet" />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    settlement: state?.mySettlements?.filter(
      (item) => item.id === localStorage.settlementID
    )[0],
    prefs: state.paymentElections,
    user:state.userData,
    rates: state.exchangeRates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleImagePreview: toggleImagePreviewAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SettlementsDetail);
