import React from "react";
import { useEffect, useState } from "react";
import Title from "../../../../components/title/Title";
import ApiCallHandler from "../../../../shared/js/ApiCallHandler";
import URLS from "../../../../shared/js/urls";
import NoItems from "../../../../tools/components/v1/widgets/NoItems/NoItems";
import Table from "../../../../tools/components/v1/widgets/Table/Table";
import {
  convertNumberToShortForm,
  getMeaningfulDate,
  getMyCurrencyInfo,
  getRate,
} from "../../../../shared/js/utils";
import { useSelector } from "react-redux";

export default function Incomes(props) {
  const [incomes, setIncomes] = useState([]);
  let rates = useSelector((state) => state.exchangeRates);
  let user = useSelector((state) => state.userData);

  useEffect(() => {
    ApiCallHandler.send(URLS.LIST_OPPORTUNITY_INCOME, "POST", {
      opportunity: props.opportunity?.id,
    }).then((res) => {
      setIncomes(res?.data);
    });
  }, [incomes, props.opportunity?.id]);

  const convertMoney = (money, code) => {
    let finalAmount = money / getRate(code, rates);
    return finalAmount;
  };
  const getUserCurrency = getMyCurrencyInfo(user?.currency);
  const prepareTableData = (incomes) => {
    let tableData = [];
    incomes.map((item) => {
      return tableData.push([
        <p>{item?.income_type?.name}</p>,
        <p>
          {convertNumberToShortForm(parseFloat(item?.amount)?.toFixed(2))} (
          {getUserCurrency?.symbol}{" "}
          {convertNumberToShortForm(
            parseFloat(convertMoney(item?.amount, currency?.code)?.toFixed(2))
          )}
          )
        </p>,
        <p>{getMeaningfulDate(item?.transaction_date?.split("T")[0])}</p>,
        <p>{item?.payment_mode}</p>,
      ]);
    });

    return tableData;
  };

  const currency = getMyCurrencyInfo(props?.opportunity?.enterprise?.currency);
  return (
    <div>
      <div style={{ borderRadius: 10 }}>
        <Title>Income List</Title>

        {incomes?.length ? (
          <Table
            columns={[
              "Income Type",
              `Amount (${currency?.symbol})`,
              "Transaction Date",
              "Payment Mode",
            ]}
            data={prepareTableData(incomes)}
            hoverAnimation={false}
          />
        ) : (
          <NoItems text="To be able to record an income, please first create an income type" />
        )}
      </div>
    </div>
  );
}
