import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleModalAction } from '../../store/actions/actions';
import MyInvestments from './my investments/MyInvestments';
import MyInvitations from './my invitations/MyInvitations';
import TabView from '../../tools/components/v1/widgets/TabView/TabView';
import './user-investments.css'

class UserInvestmentsWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { changer: "my-investments" };
  }

  TABS = [
    // {
    //   name: "Explore Investments",
    //   id: "explore-investments",
    //   component: (
    //     <ExploreInvestments
    //       switchTabs={(changer) => this.state.changer(changer)}
    //     />
    //   ),
    // },
    {
      name: "My Partnerships",
      id: "my-investments",
      component: (
        <MyInvestments
          switchTabs={(changer) => this.state.changer(changer)}
        />
      ),
    },
    {
      name: "My Invitations",
      id: "my-invitations",
      component: (
        <MyInvitations
          switchTabs={(changer) => this.state.changer(changer)}
        />
      ),
    },
  ];
  render() {
    return (
        <div className="investments-wrapper-root">
          <TabView
            data={this.TABS}
            contentAreaStyle={{ paddingTop: 30 }}
            onMount={(changer) => this.setState({ changer })}
            defaultTab={this.state.changer}
          />
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modalProps: state.modalProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInvestmentsWrapper);
