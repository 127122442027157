import React, {useState} from 'react'
import EditProfileInfo from './Tabs/EditProfile';
import CreateOrEditUserContacts from './Tabs/CreateOrEditUserContacts';
import { faAddressBook, faInfo } from '@fortawesome/free-solid-svg-icons';
import Stepper from '../../../tools/components/v1/widgets/Stepper/Stepper';

export default function EditSettingsWrapper() {

const [submitFuctionForEditProfileInfo, setSubmiFuctionForEditProfileInfo] =useState("");
const [submitFuctionForContactInfo, setSubmiFuctionForContactInfo] =useState("");
  const onNext = (step) => {
    if (step === 0) {
      submitFuctionForEditProfileInfo();
    } else if (step === 1) {
      submitFuctionForContactInfo();
    }
  };
  const steps = [
    {
      name: "Profile Info",
      component: (
        <EditProfileInfo
          onMount={(onSubmit) =>setSubmiFuctionForEditProfileInfo(() => onSubmit) }
        />
      ),
      icon: faInfo,
    },
    {
      name: "Contacts",
      component: (
        <CreateOrEditUserContacts onMount={(onSubmit) =>setSubmiFuctionForContactInfo(() => onSubmit)} />
      ),
      icon: faAddressBook,
    },
  ];
  return (
    <div>
      <Stepper variant={"horizontal"} steps={steps} onNext={onNext} />
    </div>
  );
}
