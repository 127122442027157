import React, { Component } from "react";
import { connect } from "react-redux";
import Title from "../../../components/title/Title";
import "./ReportDetail.css";
import { getMeaningfulDate, getFileType } from '../../../shared/js/utils';
import { IMAGE_TYPES, VIDEO_TYPES } from "../../../shared/js/Konstants";

class ReportDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  showMedia = (file) => {
    if (!file) return;

    let type = getFileType(file);
    
    if (IMAGE_TYPES.includes(type))
      return (
        <div id="report-card-body-images">
          <img  alt="" src={file} controls />
        </div>
      );
    else  if (VIDEO_TYPES.includes(type))
      return (
        <div id="report-card-body-images">
          <video src={file} controls />
        </div>
      );

    else
      return (
        <div
          className="pdf-container"
          onClick={() => window.open(file, "_blank")}
        >
          <p>Click to View Attachemnt</p>
        </div>
      );
  };

  // WHY: We will need comments in the future
  // showComment = () => {
  //   return (
  //     <div className="comment-item-container " style={{position:"relative"}}>
  //       {this.state.showImagePreview ? (
  //         <div
  //           id="image-preview-overlay-root"
  //           onClick={() => this.closePreview()}
  //         >
  //           <img src={this.state.imagePreviewUrl} alt="  " />
  //         </div>
  //       ) : null}
  //       <div style={{ display: "flex" }}>
  //         <div id="commentor-img-container">
  //           <img src={"https://i.pravatar.cc/300"} alt="  " />
  //         </div>
  //         <div id="commentor-info">
  //           <h4>Sam OA</h4>
  //           <small>4d</small>
  //         </div>
  //       </div>
  //       <div id="comment-body">
  //         <p>
  //           Commodo amet anim dolor proident. Esse cillum nulla dolor qui
  //           voluptate eiusmod aute Lorem. Veniam fugiat consectetur incididunt
  //           sit ea occaecat officia dolor occaecat et qui eu esse.
  //         </p>
  //       </div>
  //     </div>
  //   );
  // };
  render() {

    let { report } = this.props;
  
    return (
      <div className="report-detail-root">
        <div id="report-card-logo-and-name-container">
          <div id="report-detail-logo-container">
            <img
              src={
                report?.opportunity?.enterprise?.logo ||
                "https://i.pravatar.cc/300"
              }
              alt="  "
            />
          </div>

          <div id="report-card-opp-det">
            <h2>{report?.opportunity?.name}</h2>
            <p>{report?.opportunity?.enterprise?.name}</p>
            <small>{getMeaningfulDate(report?.date)}</small>
          </div>
        </div>

        <div id="report-title-and-body-container">
          <div id="report-detail-title-container">
            <Title>
              {report?.title || ""} -{" "}
              {`(${getMeaningfulDate(
                report?.period_start
              )}- ${getMeaningfulDate(report?.period_end)})`}
            </Title>
          </div>

          <div className="report-detail-body-container">
            <div
              className="optimum-padding-right-left report-detail-body"
              dangerouslySetInnerHTML={{
                __html: report?.body === "undefined" ? "" : report?.body,
              }}
            ></div>
            <div style={{ marginTop: "5rem" }}>
              {this.showMedia(report?.file)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    report: state.myInvestmentsReports?.filter(
      (e) => e?.id === localStorage.report_id
    )[0] || {},
  };
};


export default connect(mapStateToProps, null)(ReportDetail);
