import React, {useEffect} from "react";
import ApiCallHandler from "../../shared/js/ApiCallHandler";
import URLS from "../../shared/js/urls";

const Success = () => {

    useEffect(() => {
        let decodedData = JSON.parse(atob(window.location.href.split("entry=")[1]))
        localStorage.encoded = decodedData?.signIn;
        ApiCallHandler.send(URLS.GET_USERS, "POST", {
            id: "123e4567-e89b-12d3-a456-426614174000", 
            email: decodedData.email,
            action: "FIND_OR_CREATE"
        })
        .then(({data}) => {
            localStorage.userDeta = JSON.stringify({
              id: data?.id,
              email: data?.email,
              has_accepted_tcs: data?.has_accepted_tcs,
              country: data?.country,
                currency: data?.currency,
            });
            window.location.href = "/dashboard"
        });

       
    }, [])
    return <></>;
}


export default Success