import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Title from "../../components/title/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faInfo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import "./wallets.css";
import {
  archiveMutationAction,
  toggleModalAction,
  toggleToastAction,
} from "../../store/actions/actions";
import NoItems from "../../tools/components/v1/widgets/NoItems/NoItems";
import Table from "../../tools/components/v1/widgets/Table/Table";
import CreateAndEditPaymentElections from "./CreateAndEditPaymentElections";
import ShowActions from '../../shared/components/showActions/ShowActions';
import URLS from '../../shared/js/urls';
import ApiCallHandler from '../../shared/js/ApiCallHandler';
import { capitalizeMe, getMyCurrencyInfo } from '../../shared/js/utils';
class PaymentPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: {},
      toggleAddOrEdit: true,
      totalPercent: 0,
      showError: true,
      id: "",
      showActions: "",
    };
  }



  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };

  prepareTableData = (paymentElections) => {
    let elections =  paymentElections.map((item) => {
      let currency = getMyCurrencyInfo(item?.account?.currency);
   return [
     <p>{item?.pay_type}</p>,
     <p>{item?.account?.country}</p>,
     <p>{currency?.code} </p>,
     <p>{item?.payment_type}</p>,
     <p>{item?.account_type}</p>,
     <p>
       {capitalizeMe(
         item?.account_type === "MOBILE"
           ? item?.account?.registered_name
           : item?.account?.bank_name
       )}
     </p>,
     <p>
       *****{" "}
       {item?.account_type === "MOBILE"
         ? item?.account?.phone_number?.substring(5, 10)
         : item?.account?.account_number?.substring(10, 14)}
     </p>,
     <p>{this.showDistribution(item?.distribution)}</p>,

     <ShowActions
       onEdit={this.handleToggleEdit}
       onDelete={this.showConfirmationModal}
       item={item}
       id={this.state.id}
       toggleShowMoreOptions={this.toggleShowMoreOptions}
       show={this.state.showActions}
     />,
   ];
    })

    return elections;
  };

  showDistribution = (distribution) => {
    if (typeof distribution === "string") {
      distribution = JSON.parse(distribution);
    }
    if (distribution?.distribution === "BALANCE") {
      return "Balance";
    } else {
      return `Partial - ${distribution?.percentage}%`;
    }
  };

  toggleExit = () => {
    this.setState({
      toggleAddOrEdit: !this.state.toggleAddOrEdit,
      editData: {},
    });
  };

  calculateTotalPercentages = (id = null) => {
    let { paymentElections: elections } = this.props;
    let total = 0;
    let dist;
    let elect; // for edit purpose
    elections.forEach((election) => {
      if (typeof election.distribution === "string") {
        dist = JSON.parse(election.distribution);
      } else {
        dist = election.distribution;
      }

      if (dist.distribution === "PERCENTAGE") {
        total += Number(dist.percentage);
      }
    });

    if (id) {
      elect = elections.find((elec) => elec.id === id);
      if (typeof elect.distribution === "string") {
        dist = JSON.parse(elect.distribution);
      } else {
        dist = elect.distribution;
      }

      if (dist.distribution === "PERCENTAGE") {
        total = total - Number(dist.percentage);
      }
    }

    return total;
  };

  checkPresenceOfBalanceAccount = () => {
    let { paymentElections: elections } = this.props;
    let distribution;
    let findBalanceAccount = elections?.filter((election) => {
      if (typeof election?.distribution === "string") {
        distribution = JSON.parse(election.distribution);
      } else {
        distribution = election?.distribution;
      }

      return distribution?.distribution === "BALANCE";
    });
    if (findBalanceAccount.length > 0) {
      return true;
    }
  };

  validateConfig = () => {
    if (this.props.paymentElections?.length < 1) {
      return false;
    } else if (
      !this.checkPresenceOfBalanceAccount() &&
      this.calculateTotalPercentages() < 100
    ) {
      return true;
    }
  };

  showSetPaymentPreferenceNotification = () => {
    return (
      <div
        className="elevate-float set-payment-pref-container"
        style={{ width: "max-content" }}
      >
        <div style={{ display: "flex" }}>
          <div id="info-icon-container">
            <FontAwesomeIcon icon={faInfoCircle} />
          </div>

          <div id="notification-body-container">
            <span>No Wallet</span>
            <p>
              Please Add a wallet( Bank or Mobile Money) to set your payment
              preferences.
            </p>
          </div>
        </div>
      </div>
    );
  };

  toggleShowAddButton = () => {
    let { momo, bank } = this.props;

    if (momo?.length < 1 && bank?.length < 1) {
      return false;
    } else return true;
  };

  
  handleToggleEdit = (item) => {
    this.setState({ toggleAddOrEdit: false, editData: item });
  };

  showConfirmationModal = (item)=>{
    let {paymentElections} = this.props
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.pay_type}"`,
        children: <div style={{padding:'10px 15px'}}>Are you sure you want to delete {item?.pay_type} </div>,
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => {
            ApiCallHandler.send(URLS.ARCHIVE_USERS_PAYMENT_ELECTIONS, "POST", {
              id: item?.id,
            }).then(() => {
              this.props.archiveRecord(paymentElections, item?.id);
            });
          },
        },
      },
    });
  }

  render() {
    let { paymentElections } = this.props;

    return (
      <div id="payment-pref-root">
        {this.state.toggleAddOrEdit ? (
          <>
            {!this.toggleShowAddButton() ? (
              <div style={{ marginBottom: "2rem" }}>
                {this.showSetPaymentPreferenceNotification()}
              </div>
            ) : (
              <div
                style={{ marginBottom: "2rem" }}
                onClick={() => this.toggleExit()}
              >
                <Title className="text-toggler">
                  <FontAwesomeIcon icon={faPlus} /> Add payment election
                </Title>
              </div>
            )}

            {this.validateConfig() ? (
              <div className="error-msg-container-pref-page elevate-float">
                <div className="msg-icon-container-pref">
                  <FontAwesomeIcon icon={faInfo} />
                </div>
                <div className="msg-text-container">
                  <p>
                    {
                      "Invalid Configuration. Add a balance account for the remaining percentage."
                    }
                  </p>
                </div>
              </div>
            ) : null}

            <Title>Payment elections</Title>
            {paymentElections?.length < 1 ? (
              <NoItems text="No elections found. Click the button above to add one" />
            ) : (
              <>
                <Table
                  columns={[
                    "Pay Type",
                    "Country",
                    "Currency",
                    "Payment Type",
                    "Account Type",
                    "Account",
                    "Account Number",
                    "Distribution",
                    "Action",
                  ]}
                  data={this.prepareTableData(paymentElections)}
                  hoverAnimation={false}
                  rootClass="pref-table"
                />
              </>
            )}
          </>
        ) : (
          <CreateAndEditPaymentElections
            toggleExit={this.toggleExit}
            editData={this.state.editData}
            validatePercentage={this.calculateTotalPercentages}
            validateBalance={this.checkPresenceOfBalanceAccount}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    momo: state.momoWallets,
    bank: state.bankWallets,
    paymentElections: state.paymentElections,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
      toggleToast: toggleToastAction,
      archiveRecord: (current_data, data_to_delete_id) =>
        dispatch(
          archiveMutationAction(
            "PAYMENT_ELECTIONS",
            current_data,
            data_to_delete_id
          )
        ),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPreferences);
