import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Header from "../../components/header/Header";
import {
  ACCOUNT_TYPE,
  PAYMENT_TYPE,
  DISTRIBUTION,
} from "./Types";
import Button from "../../tools/components/v1/widgets/Button/Button";
import Dropdown from "../../tools/components/v1/widgets/Dropdown/Dropdown";
import TextBox from "../../tools/components/v1/widgets/Textboxes/Textbox";
import ApiCallHandler from "../../shared/js/ApiCallHandler";
import URLS from "../../shared/js/urls";
import {
  createMutationAction,
  editMutationAction,
  toggleToastAction,
} from "../../store/actions/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

class CreateAndEditPaymentElections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_type: "",
      account_type: "",
      account: "",
      distribution: "",
      percentage: "",
      pay_type: "",
      showError: false,
      errorMessage: "",
    };
  }

  parseString = (data) => {
    let distribution;
    if (typeof data === "string") {
      distribution = JSON.parse(data);
    } else {
      distribution = data;
    }

    return distribution;
  };
  componentDidMount() {
    let { editData } = this.props;
    let distribution;
    if (typeof editData.distribution === "string") {
      distribution = JSON.parse(editData.distribution);
    } else {
      distribution = editData.distribution;
    }
    if (editData?.id) {
      this.setState({
        payment_type: editData.payment_type,
        account_type: editData.account_type,
        account: editData.account,
        distribution: distribution?.distribution,
        percentage: distribution?.percentage,
        pay_type: editData.pay_type,
      });
    }
  }

  handleSubmit = async () => {
    let { userData } = this.props;
    let {
      payment_type,
      account_type,
      account,
      distribution,
      percentage,
      pay_type,
    } = this.state;
    let { toggleExit, editData } = this.props;
    let data = {
      user: userData?.id,
      payment_type,
      account_type: account_type,
      account: account?.id,
      distribution: JSON.stringify({ distribution, percentage }),
      pay_type,
    };

    let res;
    let validatedPercentage;
    let toEditDistribution = this.parseString(editData.distribution);
    // input fields validation
    if (
      !payment_type ||
      !account_type ||
      !account ||
      !distribution ||
      !pay_type
    )
      return this.setState({
        showError: true,
        errorMessage: "Please fill all the fields",
      });

    // balance accounts validation
  if (
    toEditDistribution?.distribution !== "BALANCE" &&
    this.state.distribution === "BALANCE"
  ) {
    if (this.props.validateBalance() && this.state.distribution === "BALANCE")
      return this.setState({
        showError: true,
        errorMessage: "You can have only one  Balance account",
      });
  }

    if (percentage) {
      validatedPercentage = parseFloat(percentage.replace(/[^0-9.]/g, ""));
      data.distribution = JSON.stringify({
        distribution,
        percentage: validatedPercentage,
      });
    }
    // percentage distribution validation
    if (
      this.props.validatePercentage() > 100 ||
      this.props.validatePercentage(editData?.id) +
        Number(validatedPercentage) >
        100
    )
      return this.setState({
        showError: true,
        errorMessage: `Total Percentage should be less than 100%. Current total is ${this.props.validatePercentage()} %`,
      });

    // if all is clear proceed to save

    this.setState({ showError: false, errorMessage: "" });
    if (!editData?.id) {
      res = await ApiCallHandler.send(
        URLS.CREATE_USERS_PAYMENT_ELECTIONS,
        "POST",
        data
      );
    } else {
      data.id = editData.id;
      res = await ApiCallHandler.send(
        URLS.UPDATE_USERS_PAYMENT_ELECTIONS,
        "POST",
        data
      );
    }
    if (res && res.success) {
      this.props.toggleToast({
        show: true,
        message: !editData?.id
          ? "Created Successfully"
          : "Updated Successfully",
        type: "success",
      });
      this.updateRedux(editData?.id, res?.data);
      toggleExit();
    } else {
      this.props.toggleToast({
        showToast: true,
        message: "An error occured. Try again",
        type: "failure",
      });
    }
  };

  toggleSelectAccount = () => {
    let { account_type } = this.state;
    let { account_type: fromProps, account } = this.props;

    if (fromProps) {
      return account && account?.length ?   [...account] : [];
    } else {
      if (account_type === "MOBILE") return this.props.momo || [];
      return this.props.bank || [];
    }
  };
  labelExtractor = (e) => {
    let { account_type } = this.state;
    if (account_type === "MOBILE") {
      return e.registered_name;
    } else return e.bank_name;
  };

  valueExtractor = (e) => {
    return e.id;
  };
  updateRedux = (isEdit, data) => {
    let { paymentElections, createRecord, editRecord } = this.props;
    if (!isEdit) {
      createRecord(paymentElections, data);
    } else {
      editRecord(paymentElections, data[0]);
    }
  };
  render() {
    let { editData, toggleExit } = this.props;
    let { distribution, showError, errorMessage } = this.state;

    return (
      <div>
        <Header title="Add a payment election" subtitle=" " />
        {showError ? (
          <div className="error-msg-container">
            <div className="msg-icon-container">
              <FontAwesomeIcon icon={faInfo} />
            </div>
            <div className="msg-text-container">
              <p>{errorMessage}</p>
            </div>
          </div>
        ) : null}

        <div className="election-form-container">
          <div className="election-form-item">
            <TextBox
              placeholder="Enter Pay type (e.g. Payroll)"
              onChange={(e, value) => this.setState({ pay_type: value })}
              value={editData?.id ? editData.pay_type : this.state.pay_type}
            />
          </div>
          <div className="election-form-item">
            <Dropdown
              data={PAYMENT_TYPE}
              label="Payment Type"
              name="payment_type"
              placeholder="select Payment Type"
              required={true}
              value={this.state.payment_type}
              type="full"
              onItemSelected={(e) => this.setState({ payment_type: e })}
            />
          </div>
          <div className="election-form-item">
            <Dropdown
              data={ACCOUNT_TYPE}
              label="Account Type"
              name="account_ype"
              placeholder="select Account Type"
              required={true}
              value={this.state.account_type}
              type="full"
              onItemSelected={(e) => this.setState({ account_type: e })}
            />
          </div>
          {this.state.account_type || editData?.account_type ? (
            <div className="election-form-item">
              <Dropdown
                data={this.toggleSelectAccount()}
                label="Account"
                name="account"
                placeholder="select Account"
                required={true}
                value={this.state.account}
                type="full"
                onItemSelected={(e) => this.setState({ account: e })}
                labelExtractor={(e) => this.labelExtractor(e)}
                valueExtractor={(e) => this.valueExtractor(e)}
              />
            </div>
          ) : null}
          <div className="election-form-item">
            <Dropdown
              data={DISTRIBUTION}
              label="Distribution"
              name="distribution"
              placeholder="select Distribution"
              required={true}
              value={this.state.distribution}
              type="full"
              onItemSelected={(e) => this.setState({ distribution: e })}
            />
          </div>

          {distribution === "PERCENTAGE" ? (
            <div className="election-form-item">
              <TextBox
                placeholder="Enter Percentage"
                onChange={(e, value) => this.setState({ percentage: value })}
                value={this.state.percentage}
              />
            </div>
          ) : null}

          <div className="buttons-container">
            <Button
              style={{ marginRight: 15 }}
              theme="danger"
              onClick={() => toggleExit()}
              size={Button.LARGE}
            >
              Cancel
            </Button>
            <Button
              theme="success"
              onClick={() => this.handleSubmit()}
              size={Button.LARGE}
            >
              submit
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    momo: state.momoWallets,
    bank: state.bankWallets,
    paymentElections: state.paymentElections,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      createRecord: (current_data, new_data) =>
        dispatch(
          createMutationAction("PAYMENT_ELECTIONS", current_data, new_data)
        ),
      editRecord: (current_data, new_data) =>
        dispatch(
          editMutationAction("PAYMENT_ELECTIONS", current_data, new_data)
        ),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAndEditPaymentElections);
