import { combineReducers } from "redux";
import {
  doNothingReducer,
  toggleSidePaneReducer,
  toggleToastReducer,
  toggleModalReducer,
  toggleLoadingBlanketReducer,
  setEditSetterReducer,
  getUserMomoWalletsReducer,
  getUserBankWalletsReducer,
  getUserInvestmentsReducer,
  listUsersPaymentElectionsReducer,
  getUserInvitationsReducer,
  userDashboardStatsReducer,
  getUserOpportunityReportsReducer,
  getUserOpportunitySettlementsReducer,
  toggleImagePreviewReducer,
  getUserInfoReducer,
  toggleOverlayReducer,
  fetchExchangeRatesReducer
} from "./all";

const motherReducer = combineReducers({
  nothing: doNothingReducer,
  sidePane: toggleSidePaneReducer,
  toastProps: toggleToastReducer,
  imagePreviewProps: toggleImagePreviewReducer,
  overlayProps: toggleOverlayReducer,
  modalProps: toggleModalReducer,
  blanketProps: toggleLoadingBlanketReducer,
  editStatusPayload: setEditSetterReducer,
  momoWallets: getUserMomoWalletsReducer,
  bankWallets: getUserBankWalletsReducer,
  myInvestments: getUserInvestmentsReducer,
  paymentElections: listUsersPaymentElectionsReducer,
  invitations: getUserInvitationsReducer,
  dashboardStats: userDashboardStatsReducer,
  myInvestmentsReports: getUserOpportunityReportsReducer,
  mySettlements: getUserOpportunitySettlementsReducer,
  userData: getUserInfoReducer,
  exchangeRates: fetchExchangeRatesReducer,
});

export default motherReducer;
