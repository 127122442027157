import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  getPropsArrayFromJsonArrayAdv,
  getRandomStringKey,
} from "../../shared/js/utils";
import "./TabView.css";
import { TAB_DATA } from "./values";
class TabView extends Component {
  static DATA_EXAMPLE = TAB_DATA;
  constructor(props) {
    super(props);
    this.state = {
      activeTabId: undefined,
      headers: undefined,
      animeKey: "3323344",
    };
    this.changeTabs = this.changeTabs.bind(this);
  }
  componentDidMount() {
    const { onMount } = this.props;
    if (onMount) onMount(this.changeTabs);
  }

  static getDerivedStateFromProps(props, state) {
    const toGo = {};
    if (state.headers === undefined) {
      // if no headers are specified, create headers from data
      const headers = getPropsArrayFromJsonArrayAdv(props.data, (item) => ({
        name: item.name,
        id: item.id,
      }));
      toGo.headers = headers;
    }

    if (!state.activeTabId && props.defaultTab)
      //set default tabid if it exists
      toGo.activeTabId = props.defaultTab;

    if (!state.activeTabId && !props.defaultTab) {
      // if not defaul ttab is set, just set the first tab item in data as default
      toGo.activeTabId = props.data[0]?.id;
    }

    return toGo;
  }

  renderComponent() {
    const { data } = this.props;
    const { activeTabId } = this.state;
    const tabItem = (data || []).find((tab) => tab.id === activeTabId);
    return tabItem?.component || <> Could not find component </>;
  }

  changeTabs = (id) => {
    this.setState({ activeTabId: id, animeKey: getRandomStringKey() });
  };

  renderTabHeaders() {
    const { headers, activeTabId } = this.state;
    return (headers || []).map((tab, index) => (
      <div
        className={`tab-header ${activeTabId === tab.id && "tab-selected"}`}
        key={index.toString()}
        onClick={() =>
          this.setState({ activeTabId: tab.id, animeKey: getRandomStringKey() })
        }
      >
        <p>{tab.name}</p>
      </div>
    ));
  }
  render() {
    const { contentAreaStyle, contentAreaClassName } = this.props;
    return (
      <div>
        <div className="tab-headers-container">{this.renderTabHeaders()}</div>
        <div
          className={`tab-content-area ${contentAreaClassName}`}
          key={this.state.animeKey}
          style={contentAreaStyle}
        >
          {this.renderComponent()}
        </div>
      </div>
    );
  }
}

TabView.propTypes = {
  /**
   * Take a look at `TabView.DATA_EXAMPLE` to see the structure of what your data prop should look like
   */
  data: PropTypes.arrayOf(PropTypes.object.object),

  /**
   * To set the preselected tab, add the id of your preferred tab
   */
  defaultTab: PropTypes.string,
  /**
   * provides a function that allows active tab changes outside the component
   * @param tabChangeFunction
   */
  onMount: PropTypes.func,
};

TabView.defaultProps = {
  data: TAB_DATA,
  defaultTab: null,
  contentAreaStyle: {},
  contentAreaClassName: "",
};

export default TabView;