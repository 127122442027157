import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoins,
  faCubes,
  faHandshake,
  faInfoCircle,
  faLongArrowAltRight,
  faMoneyBillWave,
} from "@fortawesome/free-solid-svg-icons";

import "./userDashboard.css";
import Table from "../../tools/components/v1/widgets/Table/Table";
import { connect } from "react-redux";
import Title from "../../components/title/Title";
import ApiCallHandler from "../../shared/js/ApiCallHandler";
import URLS from "../../shared/js/urls";
import NoItems from "../../tools/components/v1/widgets/NoItems/NoItems";
import {
  getAllInvestmentsAction,
  toggleToastAction,
  toggleModalAction,
  getUserInvitationsAction,
} from "../../store/actions/actions";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import {
  capitalizeMe,
  convertNumberToShortForm,
  getMeaningfulDate,
  getMyCurrencyInfo,
  getRate,
} from "../../shared/js/utils";
import { Link } from "react-router-dom";
import Verified from "../../shared/components/verified/Verified";
import ShowActions from '../../shared/components/showActions/ShowActions';

const GREETING_MESSAGE = ["Hello", "HI", "Welcome", "Welcome Back", "Hey"];

class UserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      investments: [],
      msg: 0,
      id: "",
      showActions: "",
      screenWidth:window.screen.width,
    };
  }

  componentDidMount() {
    this.setState({ msg: Math.floor(Math.random() * GREETING_MESSAGE.length) });
  }
  getCurrency = () => {
    let { userData } = this.props;
    let currency = getMyCurrencyInfo(userData?.currency);
    return currency;
  };

  showTopTenInvestments = (ivestments) => {
    let sorted = ivestments
      .sort((firstItem, secondItem) => secondItem.shares - firstItem.shares)
      .slice(0, 5);
    return sorted || [];
  };

  handleAccept = async (invitation) => {
    const dataToSend = {
      shares: invitation?.shares,
      opportunity: invitation?.opportunity.id,
      opportunity_invitation_id: invitation?.id,
      action: "CREATE_USER_AND_PARTNERSHIP",
    };
    let res;
    res = await ApiCallHandler.send(
      URLS.ACCEPT_INVITATION_PATH,
      "POST",
      dataToSend
    );
    if (res && res.success) {
      this.props.getInvestments();
      this.props.getInvitations();
      this.props.toggleToast({
        show: true,
        message: "Acceptance Successful",
        type: "success",
      });
    } else {
      this.props.toggleToast({
        showToast: true,
        message: "An error occured. Try again",
        type: "failure",
      });
    }
  };

  computeAmountInInvetsment = () => {
    let { allInvestments: partnerships, rates } = this.props;
    let total = 0;
    partnerships?.forEach((partnership) => {
      let pps = partnership?.opportunity?.price_per_share;
      let shares = partnership?.shares;
      let currency = getMyCurrencyInfo(
        partnership?.opportunity?.enterprise.currency
      );

      let amount = pps * shares;
      let converted = amount / getRate(currency?.code, rates);
      total += converted;
       ;
    });
   
    return total;
  };

  computeAmountInSettlement = () => {
    let { settlements, rates } = this.props;
    let total = 0;
    settlements
      ?.filter((item) => item?.payment_status === "COMPLETED")
      ?.forEach((settlement) => {
        let currency = getMyCurrencyInfo(
          settlement?.opportunity?.enterprise?.currency
        );
        let amount = settlement?.amount_to_receive;
        let coverted = amount / getRate(currency?.code, rates);
        total += coverted;
      });

    return total?.toFixed(2);
  };

  computeAmountInPendingSettlement = () => {
    let { settlements, rates } = this.props;
    let total = 0;
    settlements
      ?.filter((item) => item?.payment_status !== "COMPLETED")
      ?.forEach((settlement) => {
        let currency = getMyCurrencyInfo(
          settlement?.opportunity?.enterprise?.currency
        );
        let amount = settlement?.amount_to_receive;
        let coverted = amount * getRate(currency?.code, rates);
        total += coverted;
      });

    return total?.toFixed(2);
  };
  convertMoney = (money,code) => {
    let { rates } = this.props;
    let finalAmount = money /rates[code];
    return finalAmount;
  };

  computeCurrenMonthSettlement = () => {
    let { settlements, rates } = this.props;
    let total = 0;
    settlements
      ?.filter(
        (item) =>
          this.getCurrentMonthWithDate(item?.date?.split("T")[0]) ===
          this.getCurrentMonth()
      )
      ?.forEach((settlement) => {
        let currency = getMyCurrencyInfo(
          settlement?.opportunity?.enterprise?.currency
        );
        let amount = settlement?.amount_to_receive;
        let coverted = amount * getRate(currency?.code, rates);
        total += coverted;
      });

    return total?.toFixed(2);
  };

  showConfirmationModal = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Invitation Acceptance Confirmation`,
        children: (
          <div style={{ padding: "10px 15px" }}>
            Do you wish to accept this opportunity invitation from
            {item?.opportunity?.enterprise?.name} ?
          </div>
        ),
        themeColor: "var(--app-theme)",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => this.handleAccept(item),
        },
      },
    });
  };

  convertSettlementFigures = (settlement) => {
    let { rates } = this.props;
    let currency = getMyCurrencyInfo(
      settlement?.opportunity?.enterprise?.currency
    );
    return {
      amount: settlement?.amount_to_receive * getRate(currency?.code, rates),
      charges: settlement?.transaction_charges * getRate(currency?.code, rates),
      fullAmount: settlement?.full_amount * getRate(currency?.code, rates),
    };
  };

  handleViewDetails = (invitation) => {
    this.props.history.push({
      pathname: "/opportunity_invtations_action_page",
      state: { email: invitation?.email, id: invitation.id },
    });
  };
  prepareTableData = () => {
    let { investments } = this.props;
    let sorted = this.showTopTenInvestments(investments);
    return sorted.map((investment) => [
      <p>{investment?.opportunity?.name}</p>,
      <p>{investment?.opportunity?.enterprise?.name}</p>,
      <p>{investment?.shares || 0}</p>,
    ]);
  };

  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };
  prepareTableDataForIvite = () => {
    let { unaccepted_invitations, userData } = this.props;
    let userCurrency = getMyCurrencyInfo(userData?.currency);
    return unaccepted_invitations.slice(0, 5).map((item) => {
      let currency = getMyCurrencyInfo(item?.opportunity?.enterprise?.currency);

      return [
        <p>{item?.opportunity?.enterprise?.name}</p>,
        <p>{item?.opportunity.name}</p>,
        <p>{item?.shares} </p>,
        <p>
          {currency?.symbol} {item?.opportunity?.price_per_share} (
          {userCurrency?.symbol}{" "}
          {convertNumberToShortForm(
            this.convertMoney(
              item?.opportunity?.price_per_share,
              currency?.code
            )?.toFixed(2)
          )}
          )
        </p>,
        <p>{getMeaningfulDate(item?.opportunity?.close_date)} </p>,

        <ShowActions
          onView={this.handleViewDetails}
          onApproved={this.showConfirmationModal}
          item={item}
          id={this.state.id}
          toggleShowMoreOptions={this.toggleShowMoreOptions}
          show={this.state.showActions}
        />,
      ];
    });
  };
  prepareSettlementData = (settlements) => {
    return settlements?.slice(0, 3)?.map((item) => {
      let figures = this.convertSettlementFigures(item);
      return [
        <p>{getMeaningfulDate(item?.date)}</p>,
        <p>{item?.opportunity?.enterprise?.name}</p>,
        <p>{item?.opportunity?.name}</p>,
        <p className="i-am-money">
          {convertNumberToShortForm(figures?.amount?.toFixed(2))}{" "}
        </p>,
        <p style={{ color: "tomato" }}>
          {convertNumberToShortForm(figures?.charges.toFixed(2))}{" "}
        </p>,
        <p style={{ color: "grey" }}>
          {convertNumberToShortForm(figures?.fullAmount?.toFixed(2))}
        </p>,
        <p>{item?.payment_status}</p>,
      ];
    });
  };

  showIntro = () => {
    let { userData } = this.props;
 
    return (
      <div id="user-dash-intro">
        <div id="intro-txt-container" className="move-down-small">
          <p>
            {GREETING_MESSAGE[this.state.msg]}{" "}
            {capitalizeMe(userData?.first_name)}
            {""}
            {userData?.is_verified ? (
              <Verified bg="green" height={"25px"} width={"25px"} />
            ) : null}
            !
          </p>
          <span>Here is an overview of what has been happening. </span>
        </div>

        <div id="intro-stats-container">
          <div id="stat-item-container" className="move-down-small">
            <span>In Partnerships</span>
            <p>
              {this.getCurrency()?.symbol}{" "}
              {convertNumberToShortForm(
                this.computeAmountInInvetsment()?.toFixed(2)
              ) || 0.0}
            </p>
          </div>
          <div>
            <FontAwesomeIcon icon={faLongArrowAltRight} color="grey" />
          </div>
          <div className={`everyday-flex right-of-title-box `}>
            <h6>Total Settlements</h6>
            <h1>
              {this.getCurrency()?.symbol}{" "}
              {convertNumberToShortForm(this.computeAmountInSettlement()) ||
                0.0}
            </h1>
          </div>
        </div>
      </div>
    );
  };
  getCurrentMonth = () => {
    let d = new Date();
    let month = d.toLocaleString("default", {
      month: "short",
      year: "numeric",
    });
    return month;
  };
  getCurrentMonthWithDate = (date) => {
    let d = new Date(date);
    let month = d.toLocaleString("default", {
      month: "short",
      year: "numeric",
    });
    return month;
  };

  showCardInfo = () => {
    let { investments, stats } = this.props;
    let { screenWidth } = this.state;
    let myInvestments =
      investments.filter((item) => item.is_approved === true) || [];
    return (
      <div className={`dash-card-container ${screenWidth > 480 ? "elevate-float ":''}`}>
        <div className={`dash-card-item ${screenWidth<=480 ? "elevate-float mb":''}`}>
          <div className="dash-card-icon-container">
            <FontAwesomeIcon icon={faHandshake} />
          </div>
          <div className="dash-card-txt-conatainer">
            <p>Partnerships</p>
            <span>
              {myInvestments?.length < 10
                ? "0" + myInvestments?.length
                : myInvestments?.length}
            </span>
          </div>
        </div>
        <div className={`dash-card-item ${screenWidth<=480 ? "elevate-float mb":''}`}>
          <div
            className="dash-card-icon-container"
            style={{ backgroundColor: "var(--app-theme-hover)" }}
          >
            <FontAwesomeIcon icon={faMoneyBillWave} />
          </div>
          <div className="dash-card-txt-conatainer">
            <p>Pending Settlements</p>
            <span className="profit-items">
              {this.getCurrency()?.symbol}{" "}
              {convertNumberToShortForm(
                this.computeAmountInPendingSettlement()
              ) || 0.0}{" "}
            </span>
          </div>
        </div>
        <div className={`dash-card-item ${screenWidth<=480 ? "elevate-float mb":''}`}>
          <div className="dash-card-icon-container">
            <FontAwesomeIcon icon={faCoins} />
          </div>
          <div className="dash-card-txt-conatainer">
            <p>{this.getCurrentMonth()} Settlements</p>
            <span className="profit-items">
              {this.getCurrency()?.symbol}{" "}
              {convertNumberToShortForm(this.computeCurrenMonthSettlement()) ||
                0.0}{" "}
            </span>
          </div>
        </div>
        <div className={`dash-card-item ${screenWidth<=480 ? "elevate-float mb":''}`}>
          <div
            className="dash-card-icon-container"
            style={{ backgroundColor: "var(--app-theme-active)" }}
          >
            <FontAwesomeIcon icon={faCubes} />
          </div>
          <div className="dash-card-txt-conatainer">
            <p>Total Shares</p>
            <span className="profit-items">
              {convertNumberToShortForm(
                parseFloat(stats?.total_shares || 0)?.toFixed(0)
              )}{" "}
            </span>
          </div>
        </div>
      </div>
    );
  };

  showTopInvestmentsAndInvites = () => {
    let { investments } = this.props;
    let { unaccepted_invitations } = this.props;
    return (
      <div className="dashboard-investments-invites-container">
        {unaccepted_invitations?.length ? (
          <div className="dash-invites-container elevate-float invitation-list">
            <Title>Invitations</Title>
            {this.props.unaccepted_invitations?.length > 0 ? (
              <Table
                columns={[
                  "Enterprise",
                  "Opportunity",
                  "Shares",
                  "PPS",
                  "closes on",
                  "\t",
                ]}
                data={this.prepareTableDataForIvite()}
                hoverAnimation={false}
              />
            ) : (
              <NoItems text="You have no Invitations" />
            )}
          </div>
        ) : null}

        <div className="top-investors-container elevate-float inventments-lists">
          <Title>Top Partnerships</Title>
          {investments?.length > 0 ? (
            <Table
              columns={["Opportunity", "Enterprise", "Shares"]}
              data={this.prepareTableData()}
              hoverAnimation={false}
            />
          ) : (
            <NoItems text="You have no Investments" />
          )}
        </div>
      </div>
    );
  };
  showPaymentsAndBarChart = () => {
    let { settlements } = this.props;
    return (
      <div className="dashboard-transaction-bar-container">
        <div className="top-investors-container">
          <Title>Recent settlements</Title>
          {settlements.length > 0 ? (
            <Table
              columns={[
                "Date",
                "Sender",
                "Opportunity",
                `Amount (${this.getCurrency()?.symbol})`,
                `Charges (${this.getCurrency()?.symbol})`,
                `Full Amount  (${this.getCurrency()?.symbol})`,
                `Status`,
              ]}
              data={this.prepareSettlementData(settlements)}
              hoverAnimation={false}
              rootClass="settlements-table"
            />
          ) : (
            <NoItems text="You have no settlements" />
          )}
        </div>
      </div>
    );
  };

  showYear = () => {
    return new Date().getFullYear();
  };

  showSetPaymentPreferenceNotification = () => {
    let { paymentPrefs: prefs } = this.props;

    if (prefs.length > 0) return;

    return (
      <div className="elevat-float set-payment-pref-container">
        <div style={{ display: "flex" }}>
          <div id="info-icon-container">
            <FontAwesomeIcon icon={faInfoCircle} />
          </div>

          <div id="notification-body-container">
            <span>Payment Preference</span>
            <p>
              It looks like you have not configured your payment preferences.
              Payment preference is a requirement for settlements.{" "}
              <Link to="/wallets"> Click</Link> to configure.{" "}
            </p>
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div style={{ width: "90%", margin: "auto", marginTop: "1rem" }}>
        {this.showIntro()}

        {this.showSetPaymentPreferenceNotification()}
        {this.showCardInfo()}
        {this.showTopInvestmentsAndInvites()}
        {this.showPaymentsAndBarChart()}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    investments:
      state?.myInvestments && state?.myInvestments?.filter
        ? state?.myInvestments?.filter(
            (investment) => investment.is_approved === true
          )
        : [],
    unaccepted_invitations:
      state?.invitations && state?.invitations?.filter
        ? state?.invitations?.filter(
            (invitation) => invitation.has_accepted === null
          )
        : [],
    stats: state.dashboardStats,
    settlements: state.mySettlements,
    userData: state.userData,
    paymentPrefs: state.paymentElections,
    rates: state.exchangeRates,
    allInvestments: state.myInvestments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getInvestments: getAllInvestmentsAction,
      toggleToast: toggleToastAction,
      toggleModal: toggleModalAction,
      getInvitations: getUserInvitationsAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserDashboard)
);
