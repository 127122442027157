import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faImage,
  faFileAlt,
  faFileVideo,
} from "@fortawesome/free-solid-svg-icons";
import Table from "../../../../tools/components/v1/widgets/Table/Table";
import NoItems from "../../../../tools/components/v1/widgets/NoItems/NoItems";
import Header from '../../../../components/header/Header';
import { getFileType, getMeaningfulDate } from "../../../../shared/js/utils";
import { IMAGE_TYPES, VIDEO_TYPES } from "../../../../shared/js/Konstants";

class InvestmentDocuments extends Component {
  handleOpenDocOrImage = (doc) => {
    window.open(doc, "_blank");
  };


  showType = (file) => {
    if(!file) return ;
    const fileType = getFileType(file)

    if (IMAGE_TYPES.includes(fileType))
      return <FontAwesomeIcon icon={faImage} fontSize="3rem" color="maroon" />;
    else if (fileType === "pdf")
      return (
        <FontAwesomeIcon icon={faFilePdf} fontSize="3rem" color="maroon" />
      );
    else if (VIDEO_TYPES.includes(fileType))
      return (
        <FontAwesomeIcon icon={faFileVideo} fontSize="3rem" color="maroon" />
      );

    return <FontAwesomeIcon icon={faFileAlt} fontSize="3rem" color="maroon" />;
  };

  prepareTableData = (docs =[]) => {
    let tableData = docs?.filter(item=> item?.is_published === true)?.map((item, index) => {
       return [
         <p>{getMeaningfulDate(item?.date)}</p>,
         <p onClick={() => this.handleOpenDocOrImage(item)} id="doc-name-txt">
           {item?.title}
         </p>,
         <p id="doc-type-container">
           {getFileType(item?.document).toUpperCase()}
         </p>,
         <p onClick={() => this.handleOpenDocOrImage(item?.document)}>View</p>,
       ];
    });
    return tableData;
  };
  render() {
    let { investment } = this.props;
    return (
      <div id="investment-docs-root">
        <div style={{marginTop:"2rem", marginBottom:"1rem"}}>
          <Header title="Documents From Enterprise " subtitle="  "/>
          {investment?.opportunity?.documents ? (
            <Table
              columns={["Created At","Name", "Type", "Open File"]}
              data={this.prepareTableData(investment?.opportunity?.documents)}
              hoverAnimation={false}
              rootClass="opp-docs-table"
            />
          ) : (
            <NoItems text="No document added by enterprise" />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    investment: state.myInvestments?.filter(
      (investment) => investment.opportunity.id === localStorage.investmentId
    )[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvestmentDocuments);
