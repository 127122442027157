import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./UserSettings.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faPen } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../tools/components/v1/widgets/Spinner/Spinner";
import {
  toggleModalAction,
  toggleToastAction,
  updateUserInfoAction,
} from "../../store/actions/actions";
import ApiCallHandler from "../../shared/js/ApiCallHandler";
import URLS from "../../shared/js/urls";
import Verified from "../../shared/components/verified/Verified";
import { withRouter } from "react-router-dom";
import {
  faFacebookSquare,
  faInstagramSquare,
  faLinkedin,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import { capitalizeMe, parseMe, isEmpty } from '../../shared/js/utils';

class UserSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      profileImage: null,
      toggleEdit: false,
    };
  }

  handleSelectImage = () => {
    let fileInput = document.getElementById("profile-input");
    fileInput.click();

    let { userData } = this.props;

    fileInput.onchange = async (e) => {
      // send api request to upload image
      this.setState({ uploading: true });
      let res = await ApiCallHandler.send(
        URLS.UPDATE_USER_PROFILE_IMAGE,
        "POST",
        {
          image: e.target.files[0],
          user: userData?.id,
        }
      );

      if (res && res.success) {
        this.setState({ uploading: false, profileImage: res?.data?.image });
        this.props.updateUserInfo(res?.data);
      } else {
        this.setState({ uploading: false });
        this.props.toggleToast({
          showToast: true,
          message: "An error occured. Try again",
          type: "failure",
        });
      }
    };
  };

  toggleProfileImage = () => {
    let { profileImage } = this.state;
    let { userData } = this.props;

    if (profileImage) return profileImage;
    else if (userData?.image) return userData?.image;
    else
      return "https://w7.pngwing.com/pngs/247/564/png-transparent-computer-icons-user-profile-user-avatar-blue-heroes-electric-blue.png";
  };

  close = () => {
    this.props.toggleModal({
      show: false,
      props: {},
    });
  };

  toggleEditModal = () => {
    this.props.history.push("/settings/update");
  };

  openLink = (link) => {
    window.open(link, "_blank");
  };
  renderSocialMedia = (contacts) => {
    if (isEmpty(contacts)) {
      return (
        <>
          <div id="social-media-container">
            {contacts?.website && (
              <FontAwesomeIcon
                icon={faGlobe}
                onClick={() => this.openLink(contacts?.website)}
              />
            )}
            {contacts?.twiter && (
              <FontAwesomeIcon
                icon={faTwitterSquare}
                onClick={() => this.openLink(contacts?.twitter)}
              />
            )}
            {contacts?.facebook && (
              <FontAwesomeIcon
                icon={faFacebookSquare}
                onClick={() => this.openLink(contacts?.facebook)}
              />
            )}
            {contacts?.instagram && (
              <FontAwesomeIcon
                icon={faInstagramSquare}
                onClick={() => this.openLink(contacts?.instagram)}
              />
            )}
            {contacts?.linkedin && (
              <FontAwesomeIcon
                icon={faLinkedin}
                onClick={() => this.openLink(contacts?.linkedin)}
              />
            )}
          </div>
        </>
      );
    }
  };
  showPhoneContacts = (contacts) => {
    return (
      <div id="social-media-container">
        {contacts?.primary_phone && <span>{contacts?.primary_phone}</span>}
        {contacts?.secondary_phone && (
          <span>
            {", "}
            {contacts?.secondary_phone}
          </span>
        )}
      </div>
    );
  };

  showProfileInfo = () => {
    let { userData } = this.props;
    return (
      <div id="profile-info-container">
        <div id="img-info-container">
          <div id="profile-img-container" style={{ position: "relative" }}>
            <img src={this.toggleProfileImage()} id="profile-img" alt="  " />
            <p
              className="edit-user-profile-btn"
              onClick={() => this.toggleEditModal()}
            >
              Update information
            </p>
            {this.state.uploading ? (
              <center className="loading-img-container">
                <Spinner />
              </center>
            ) : null}
            <input
              type="file"
              style={{ display: "none" }}
              id="profile-input"
              accept="image/png, image/jpg, image/jpeg"
            />
            <div onClick={this.handleSelectImage} id="choose-icon">
              <FontAwesomeIcon icon={faPen} id="camera-icon" />
            </div>
          </div>
          <div id="info-container">
            <h3>
              {capitalizeMe(userData?.last_name)} {capitalizeMe(userData?.first_name)}
              {userData?.is_verified ? (
                <Verified bg={"green"} height={"25px"} width={"25px"} />
              ) : null}
            </h3>
            {userData?.username && <p>@{userData?.username?.toLowerCase()}</p>}
            <p>{userData?.email}</p>
            {this.showPhoneContacts(userData?.contacts)}
            {this.renderSocialMedia(userData?.contacts)}
          </div>
        </div>
      </div>
    );
  };

  render() {
    let { userData } = this.props;
    return (
      <div id="user-setting-root" key={`${this.state.uploading}`}>
        {this.showProfileInfo()}

        <div className="interests-container">
          {isEmpty(parseMe(userData?.interests?.interests)) && (
            <h3>Interests</h3>
          )}

          <div className="interests-wrapper">
            {userData?.interests &&
              parseMe(userData?.interests?.interests).map((interest) => {
                return (
                  <div className="elevate-float interest-item">
                    <p>{interest}</p>
                  </div>
                );
              })}
          </div>
        </div>

        <div id="biography-conatiner">
          <h3>
            Biography
            <FontAwesomeIcon
              icon={faPen}
              className="edit-details-svg"
              onClick={() => this.toggleEditModal()}
            />
          </h3>

          <div
            style={{ cursor: "pointer" }}
            dangerouslySetInnerHTML={{
              __html: !isEmpty(userData?.bio)
                ? userData?.bio
                : "No biography added",
            }}
          ></div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userData: state?.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      updateUserInfo: updateUserInfoAction,
      toggleModal: toggleModalAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserSettings)
);
