import React, { Component } from "react";
import { connect } from "react-redux";
import ReportCard from "../report-card/ReportCard";
import Header from "../../../components/header/Header";
import "./ReportList.css";
import NoItems from "../../../tools/components/v1/widgets/NoItems/NoItems";

class ReportsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    let { reports } = this.props;
    return (
      <div
        style={{
          width: "90%",
          margin: "auto",
          position: "relative",
        }}
      >
        <Header
          title="Partnerships Updates"
          subtitle="Get the latest feed on all your Partnerships"
        />

        <div className="reports-list-container">
          {reports?.length > 0 ? (
            reports.map((report, index) => {
              return <ReportCard key={index} report={report} />;
            })
          ) : (
            <NoItems text="No report added yet." />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    reports: state.myInvestmentsReports,
  };
};


export default connect(mapStateToProps, null)(ReportsList);
