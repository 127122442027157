import React from 'react';
import { useEffect, useState } from 'react';
import Title from '../../../../components/title/Title';
import ApiCallHandler from '../../../../shared/js/ApiCallHandler';
import URLS from '../../../../shared/js/urls';
import NoItems from '../../../../tools/components/v1/widgets/NoItems/NoItems';
import Table from '../../../../tools/components/v1/widgets/Table/Table';
import { convertNumberToShortForm, getMeaningfulDate, getMyCurrencyInfo, getRate } from '../../../../shared/js/utils';
import { useSelector } from 'react-redux';

export default function Expenses(props) {
    const [expenses, setExpenses] = useState([]);
    let rates = useSelector((state) => state.exchangeRates)
    let user = useSelector((state) => state.userData)

    useEffect(() => {
        ApiCallHandler.send(URLS.LIST_OPPORTUNITY_EXPENSE, "POST", {opportunity:props.opportunity?.id}).then(res => {
            setExpenses(res?.data);
        })
    }, [expenses, props.opportunity?.id]);



    const convertMoney = (money, code) => {;
      let finalAmount = money / getRate(code, rates);
      return finalAmount;
    };
    const getUserCurrency = getMyCurrencyInfo(user?.currency);
     const  prepareTableData = (expenses) => {

        let tableData = [];
          expenses.map((item) => {
           return tableData.push([
             <p>{item?.expense_type?.name}</p>,
             <p>
               {convertNumberToShortForm(parseFloat(item?.amount)?.toFixed(2))}{" "}
               ({getUserCurrency?.symbol}{" "}
               {convertNumberToShortForm(
                 parseFloat(
                   convertMoney(item?.amount, currency?.code)?.toFixed(2)
                 )
               )}
               )
             </p>,
             <p>
               {getMeaningfulDate(item?.transaction_date?.split("T")[0])}
             </p>,
           ]);
          });

        return tableData;
      };


      const currency = getMyCurrencyInfo(props?.opportunity?.enterprise?.currency);
    
  return (
    <div>
      <div style={{ borderRadius: 10 }}>
        <Title>Expense List</Title>
        {expenses?.length ? (
          <Table
            columns={[
              "Expense Type",
              `Amount (${currency?.symbol})`,
              "Transaction Date",
            ]}
            data={prepareTableData(expenses)}
            hoverAnimation={false}
          />
        ) : (
          <NoItems text="To be able to record an expense, please first create an expense type" />
        )}
      </div>
    </div>
  );
}
