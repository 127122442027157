import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../../../tools/components/v1/widgets/Table/Table";
import Header from "../../../components/header/Header";
import "./SettlementsList.css";
import NoItems from '../../../tools/components/v1/widgets/NoItems/NoItems';
import { convertNumberToShortForm, getMeaningfulDate, getMyCurrencyInfo, getRate } from "../../../shared/js/utils";
class SettlementsList extends Component {


  prepareTableData = (settlements) => {
    return settlements.map((item) => {
      let figures = this.convertSettlementFigures(item)
      return [
        <p onClick={() => this.handleViewDetails(item?.id)}>
          {getMeaningfulDate(item?.date)}
        </p>,
        <p onClick={() => this.handleViewDetails(item?.id)}>
          {item?.opportunity?.enterprise?.name}
        </p>,
        <p>{item?.opportunity?.name}</p>,
        <p className="i-am-money">
          {convertNumberToShortForm(figures?.amount.toFixed(2))}{" "}
        </p>,
        <p style={{ color: "tomato" }}>
          {convertNumberToShortForm(figures?.charges.toFixed(2))}{" "}
        </p>,
        <p style={{ color: "grey" }}>
          {convertNumberToShortForm(figures?.fullAmount.toFixed(2))}
        </p>,
        <p>{item?.payment_status}</p>,
      ];
    });
  };

  handleViewDetails = (id) => {
    localStorage.settlementID = id;
    this.props.history.push(`/settlements/${id}`);
  }


  convertSettlementFigures = (settlement) => {
    let {rates} = this.props;
    let currency = getMyCurrencyInfo(settlement?.opportunity?.enterprise?.currency)
    return {
      amount: settlement?.amount_to_receive * getRate(currency?.code, rates),
      charges: settlement?.transaction_charges * getRate(currency?.code, rates),
      fullAmount: settlement?.full_amount * getRate(currency?.code, rates),
    }

  }

  render() {
    let { settlements, user} = this.props;
    let currency =  getMyCurrencyInfo(user?.currency);
    return (
      <div style={{ width: "92%", margin: "auto", marginTop: "1rem" }}>
        <Header
          title="Settlements"
          subtitle="Here are a list of your overall settlemnts"
        />

        <div style={{ marginTop: "2rem" }}>
          {settlements.length > 0 ? (
            <Table
              columns={[
                "Date",
                "Sender",
                "Opportunity",
                `Amount (${currency.symbol})`,
                `Charges (${currency.symbol})`,
                `Full Amount  (${currency.symbol})`,
                "Status",
              ]}
              data={this.prepareTableData(settlements)}
              hoverAnimation={false}
              rootClass="settlements-table"
            />
          ) : (
            <NoItems text="No Settlements" />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    settlements:state.mySettlements,
    user:state.userData,
    rates:state.exchangeRates
  };
};

export default connect(mapStateToProps, null)(SettlementsList);
