import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FormGenerator from "../../../../tools/components/v1/FormGenerator/FormGenerator";
import ApiCallHandler from "../../../../shared/js/ApiCallHandler";
import URLS from "../../../../shared/js/urls";
import { updateUserInfoAction } from "../../../../store/actions/actions";
import { withRouter } from 'react-router-dom';

class CreateOrEditUserContacts extends Component {
  constructor(props) {
    super(props);
    this.contactInfoRef = React.createRef();
  }

  componentDidMount() {
    let { onMount } = this.props;
    if (onMount) {
      onMount(() => this.saveDataToDB());
    }
  }

  saveDataToDB = async () => {
    let { user } = this.props;
    const data = this.contactInfoRef.current?.onClickFromOutside();
    let res;
    if (data) {
      data.user = user?.id;
      if (user?.contacts !== null) {
        data.id = user?.contacts?.id;
        res = await ApiCallHandler.send(URLS.UPDATE_CONTACTS, "POST", data);
      } else {
        res = await ApiCallHandler.send(URLS.CREATE_CONTACTS, "POST", data);
      }

      if (res && res?.success) {
    let newUser = user;
     newUser.contacts =user?.contacts !== null ? res?.data[0] : res?.data;
        this.props.updateUserInfo(newUser)
        this.props.history.push('/settings')
      }
    }
  };
  render() {
    let { user } = this.props;
    const formData = [
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "General email",
        name: "primary_email",
        placeholder: "General email or official email",
        required: true,
        value: user?.id ? user?.contacts?.primary_email : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Other email",
        name: "secondary_email",
        placeholder: "Secondary email",
        value: user?.id ? user?.contacts?.secondary_email : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Primary phone Number",
        name: "primary_phone",
        placeholder: "Primary phone number (e.g. +233244445555)",
        required: true,
        value: user?.id ? user?.contacts?.primary_phone : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Secondary Phone Number",
        name: "secondary_phone",
        placeholder: "Secondary Phone Number (e.g. +233244445555)",
        value: user?.id ? user?.contacts?.secondary_phone : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Website URL",
        name: "website",
        placeholder: "Website URL",
        value: user?.id ? user?.contacts?.website : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Twitter URL",
        name: "twitter",
        placeholder: "Twitter handle URL",
        value: user?.id ? user?.contacts?.twitter : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Facebook",
        name: "facebook",
        placeholder: "Facebook handle URL",
        value: user?.id ? user?.contacts?.facebook : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Instagram",
        name: "instagram",
        placeholder: "Instagram handle URL",
        value: user?.id ? user?.contacts?.instagram : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "LinkedIn",
        name: "linkedin",
        placeholder: "LinkedIn handle URL",
        value: user?.id ? user?.contacts?.linkedin : "",
      },
    ];

    return (
      <div>
        {" "}
        <FormGenerator
          elevation={0}
          subtitle=""
          title=""
          formSubmitBtnText={"Save Changes"}
          fields={formData}
          hideSubmitBtn={true}
          ref={this.contactInfoRef}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
      updateUserInfo: updateUserInfoAction
  }, dispatch);
};
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOrEditUserContacts));
