import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TabView from "../../../tools/components/v1/widgets/TabView/TabView";
import { toggleModalAction } from "../../../store/actions/actions";
import InvestmentDetails from './tab-items/InvestmentDetails';
import InvestmentAssets from './tab-items/InvestmentAssets';
import InvestmentDocuments from './tab-items/InvestmentDocuments';
import InvestmentDetailReports from './tab-items/InvestmentDetailReports';
import AquisitionStatus from "./tab-items/AquisitionStatus";
import Expenses from './tab-items/Expenses';
import Incomes from './tab-items/Incomes';
import '../user-investments.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { capitalizeMe, convertNumberToShortForm, getMyCurrencyInfo, getRate } from '../../../shared/js/utils';

class InvestmentDetailWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { changer: "opp-details" };
  }

  TABS = [
    {
      name: "Details",
      id: "opp-details",
      component: (
        <InvestmentDetails
          switchTabs={(changer) => this.state.changer(changer)}
        />
      ),
    },
    {
      name: "Assets",
      id: "assets-involved",
      component: (
        <InvestmentAssets
          switchTabs={(changer) => this.state.changer(changer)}
        />
      ),
    },
    {
      name: "Documents",
      id: "docs-involved",
      component: (
        <InvestmentDocuments
          switchTabs={(changer) => this.state.changer(changer)}
        />
      ),
    },

    {
      name: "Reports",
      id: "opp-reports",
      component: (
        <InvestmentDetailReports
          switchTabs={(changer) => this.state.changer(changer)}
        />
      ),
    },
    {
      name: "Incomes",
      id: "Incomes",
      component: (
        <Incomes
          switchTabs={(changer) => this.state.changer(changer)}
          opportunity={this.props.investment?.opportunity}
        />
      ),
    },

    {
      name: "Expenses",
      id: "Expenses",
      component: (
        <Expenses
          switchTabs={(changer) => this.state.changer(changer)}
          opportunity={this.props.investment?.opportunity}
        />
      ),
    },
  ];

  PENDING_TABS = [
    {
      name: "Details",
      id: "opp-details",
      component: (
        <InvestmentDetails
          switchTabs={(changer) => this.state.changer(changer)}
        />
      ),
    },
    {
      name: "Acquisition Update",
      id: "acquisition-status",
      component: (
        <AquisitionStatus
          switchTabs={(changer) => this.state.changer(changer)}
        />
      ),
    },
  ];

  getOpportunityValuation = () => {
    const { investment, rates } = this.props;
        const enterpriseCurrency = getMyCurrencyInfo(
          investment?.opportunity.enterprise?.currency
        );
        let pps = investment?.opportunity?.price_per_share;
        let shares = investment?.opportunity?.shares;
        let userShares = investment?.shares;
        let totalValuation = pps*shares;
        let userTotalValuation = pps*userShares;
        return {
          total: totalValuation / getRate(enterpriseCurrency.code, rates),
          userTotal: userTotalValuation / getRate(enterpriseCurrency.code, rates),
        };
  };

  getTotalRevenue = ()=>{
    const { settlements, rates, user , investment} = this.props;
    const enterpriseCurrency = getMyCurrencyInfo(
      investment?.opportunity.enterprise?.currency
    );
    let total = 0
    settlements
      ?.filter(
        (item) =>
          item?.partner?.user?.id === user?.id &&
          item?.opportunity?.id === investment?.opportunity?.id &&
          item?.payment_status === "COMPLETED"
      ) // eslint-disable-next-line
      .map((item) => {
        total += item?.amount_to_receive;
      });

    return total/getRate(enterpriseCurrency.code, rates);
  }

  render() {
    const { investment, user } = this.props;
    const userCurrency = getMyCurrencyInfo(user?.currency);
    return (
      <div style={{ width: "93%", margin: "auto" }}>
        {investment?.is_approved ? (
          <div className="flex-me stats-root">
            <div className="partnership-opp-name">
              <h1>
                <p>{capitalizeMe(investment?.opportunity?.name)}</p>
              </h1>
            </div>

            <div className="flex-item">
              <div
                className="partnership-opp-name opp-valuation space-me-right"
                style={{ flexDirection: "column" }}
              >
                <h6>Opportunity Valuation</h6>
                <h1>
                  {userCurrency?.symbol}{" "}
                  {convertNumberToShortForm(
                    this.getOpportunityValuation()?.total?.toFixed(2)
                  )}
                </h1>
              </div>
              <div className="partnership-opp-name space-me-right vanish">
                <h1>
                  <FontAwesomeIcon icon={faLongArrowAltRight} />
                </h1>
              </div>
              <div className="everyday-flex right-of-title-box">
                <h6>Total Revenue</h6>
                <h1>
                  {userCurrency?.symbol}{" "}
                  {convertNumberToShortForm(this.getTotalRevenue().toFixed(2))}
                </h1>

                <h6 style={{ marginTop: 10 }}>My Stake</h6>
                <h2>
                  {userCurrency?.symbol}{" "}
                  {convertNumberToShortForm(
                    this.getOpportunityValuation()?.userTotal?.toFixed(2)
                  )}
                </h2>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ marginBottom: "2rem" }}>
            <h1>
              <p>{investment?.opportunity?.name}</p>
            </h1>
          </div>
        )}

        <TabView
          data={investment?.is_approved ? this.TABS : this.PENDING_TABS}
          contentAreaStyle={{ paddingTop: 30 }}
          onMount={(changer) => this.setState({ changer })}
          defaultTab={this.state.changer}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    investment: state.myInvestments?.filter(
      (investment) => investment.opportunity.id === localStorage.investmentId
    )[0],
    user:state.userData,
    rates:state.exchangeRates,
    settlements:state.mySettlements,

  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvestmentDetailWrapper);
