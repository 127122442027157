import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import NoItems from "../../../../tools/components/v1/widgets/NoItems/NoItems";
import ReportCard from "../../../investment reports/report-card/ReportCard";
import Header from '../../../../components/header/Header';

class InvestmentDetailReports extends Component {

  reCreateReportData = (investment) => {
    let obj = {};
    let reports = [];

    investment?.opportunity?.reports?.forEach((rep) => {
      obj = {
        ...rep,
        opportunity: {
          name: investment?.opportunity?.name,
          enterprise: {
            name: investment?.opportunity?.enterprise?.name,
            logo: investment?.opportunity?.enterprise?.logo,
          },
        },
      };

      reports.push(obj);
    });

    return reports;
  };

  render() {
    let { investment } = this.props;
    return (
      <div id="opp-detail-report-root">
        <Header title="Updates on Partnership " subtitle="  " />

        <div className="list-root">
          {investment?.opportunity?.reports ? (
            this.reCreateReportData(investment)?.map((report, index) => (
              <ReportCard
                key={index}
                report={report}
              />
            ))
          ) : (
            <NoItems text="No Report Created" />
          )}
        </div>


      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    investment: state.myInvestments?.filter(
      (investment) => investment.opportunity.id === localStorage.investmentId
    )[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvestmentDetailReports);
