export const ACCORDION_DATA = [
  {
    title: "Sam O.A - $1,000,000",
    content:
      "Non odit magnam dolorum. Et odio et maxime consequuntur provident. Error eaque est dolor et qui. Ex odit doloremque consequatur quis. Eaque et pariatur dolores. Magni in quasi dolor repudiandae explicabo.",
  },
  {
    title: "Sharon B. - $1,000,000",
    content:
      "Quos quam ipsam consequatur consequatur et distinctio. Facere vel ut dolorem. Quam quo neque quos voluptates cupiditate sit quae.",
  },
  {
    title: "Charles O.A - $1,000,000",
    content:
      "Vel et quam reprehenderit velit. Possimus accusamus eos esse vero quo modi voluptas hic. Quia illo quisquam vel quis qui. Autem labore aut incidunt. Eius non voluptatem et laboriosam in.",
  },
];
