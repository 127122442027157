import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggleSidePane } from "../../store/actions/actions";
// import Navbar from '../navbar/Navbar';
import { MENU, BOTTOM_MENU } from "./values";
import Sidebar from '../../tools/components/v1/widgets/Sidebar/Sidebar';
import './userPageWrapper.css'
import { withRouter } from 'react-router-dom';
const SHRINK_SIDE_RATIO = 71;
const EXPANDED_SIDE_RATIO = 271;

class UserPageWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { isUntouched: true };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      state.isUntouched &&
      (state.sideRatio !== props.sideRatio ||
        state.contentRatio !== props.contentRatio)
    ) {
      return {
        sideRatio: props.sideRatio,
        contentRatio: props.contentRatio,
        isUntouched: false,
      };
    }

    return null;
  }
  getContentRatio() {
    var { sideRatio } = this.state;
    sideRatio = sideRatio || 0;
    var contentRatio = 10 - sideRatio;
    return [sideRatio, contentRatio];
  }

  handleSidebarStateChange = (state) => {
    if (!state.shrink && this.state.sideRatio !== EXPANDED_SIDE_RATIO)
      this.setState({ sideRatio: EXPANDED_SIDE_RATIO });
    else if (state.shrink && this.state.sideRatio !== SHRINK_SIDE_RATIO)
      this.setState({ sideRatio: SHRINK_SIDE_RATIO });
  }
  render() {
    const { sideRatio } = this.state;
    const { sidePane } = this.props;
    return (
      <div
        className="root-wrapper"
        style={{
          "--side-ratio": `${sideRatio}px`,
        }}
      >
        <div>
          <Sidebar
            menu={MENU}
            dark
            bottomMenu={BOTTOM_MENU}
            // details={userData}
            onShrinkBtnClick={() =>
              this.setState({ sideRatio: SHRINK_SIDE_RATIO })
            }
            onStateChange={this.handleSidebarStateChange}
          />
        </div>
        <div className="children-container">
          {sidePane?.on && (
            <div className="side-pane elevate-float animate-side-pane"></div>
          )}
          <div>
            {/* <Navbar /> */}
        
          </div>
          <div>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

UserPageWrapper.defaultProps = {
  sideRatio: SHRINK_SIDE_RATIO,
};

const mapStateToProps = (store) => {
  return {
    sidePane: store.sidePane,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSidePane: toggleSidePane,
    },
    dispatch
  );
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPageWrapper));
