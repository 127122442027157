const Fields = {
  INPUT: "input",
  CHECKBOX: "checkbox",
  RADIOGROUP: "radio-group",
  CHECKBOXGROUP: "checkbox-group",
  TEXTAREA: "textarea",
  IMAGE: "image",
  FILE: "file",
  BUTTON: "button",
  DROPDOWN: "dropdown",
  HTMLFIELD: "html-field",
  RICHTEXT: "rich-text",
  INCREMENT: "increment",
  DATE: "date",
  AUTOCOMPLETE: "auto-complete",
  TAG:"tag"
};

export default Fields;
