import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ErrorPage from "./pages/error/ErrorPage";
import Landing from "./pages/landing/Landing";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  toggleToastAction,
  toggleModalAction,
  toggleLoadingBlanket,
  getUserMomoWalletsAction,
  getUserBankWalletsAction,
  getAllInvestmentsAction,
  listUsersPaymentElectionsAction,
  userDashboardStatsAction,
  getAllOpportunityReportsAction,
  getAllSettlementsAction,
  toggleImagePreviewAction,
  getUserInfoAction,
  getUserInvitationsAction,
  fetchExchangeRatesAction,
} from "./store/actions/actions";
import Toast from "./tools/components/v1/widgets/Toast/Toast";
import Modal from "./tools/components/v1/widgets/Modal/Modal";
import LoadingPage from "./shared/components/loading/LoadingPage";
import opportunitiesResponsePage from "./pages/opportunitiesResponsePage";
import UserDashboard from "./pages/user dashboard/UserDashboard";
import UserInvestmentWrapper from "./pages/user investments/UserInvestmentWrapper";
import WalletsWrapper from "./pages/user wallets/WalletsWrapper";
import UserPageWrapper from "./components/UserPageWrapper/UserPageWrapper";
import Success from "./pages/success/Success";
import SettlementsList from "./pages/settlements/settlements-list/SettlementsList";
import ReportsList from "./pages/investment reports/reports-list/ReportsList";
import ReportDetail from "./pages/investment reports/report-details/ReportDetail";
import SettlementsDetail from "./pages/settlements/settlement-details/SettlementsDetail";
import InvestmentDetailWrapper from "./pages/user investments/investment details/InvestmentDetailWrapper";
import ImagePreview from "./tools/components/v1/widgets/ImagePreview/ImagePreview";
import Spinner from "./tools/components/v1/widgets/Spinner/Spinner";
import InvitationDetailPage from "./pages/user investments/my invitations/InvitationDetailPage";
import URLS from "./shared/js/urls";
import TermAndConditions from "./pages/terms and conditions/TermAndConditions";
import UserSettingsWrapper from "./pages/user settings/tab items/UserSettingsWrapper";
import EditSettingsWrapper from "./pages/user settings/edit-settings/EditSettingsWrapper";
import EnterpriseDetails from "./pages/enterprise detail/EnterpriseDetails";
import Overlay from "./shared/components/overlay/Overlay";
import AssetDetail from "./pages/user investments/investment details/asset detail/AssetDetail";
import CountryAndCurrencySelection from "./pages/user settings/country-and-currency/CountryAndCurrencySelection";
import { getMyCurrencyInfo } from "./shared/js/utils";

class App extends Component {
  async componentDidMount() {
    this.props.getuserInfo();
    this.props.getMomoWallets();
    this.props.getBankWallets();
    this.props.getAllInvestments();
    this.props.getUsersPaymentElections();
    this.props.getUserInvitations();
    this.props.getDashboardStats();
    this.props.getOpportunityReports();
    this.props.getAllSettlements();
  }
  render() {
    const { blanketProps } = this.props;
    if (blanketProps.show)
      return (
        <LoadingPage
          {...blanketProps.props}
          close={this.props.toggleLoadingBlanket}
        />
      );

    return (
      <>
        <NotificationManager
          close={this.props.toggleToast}
          {...this.props.toastProps}
        />
        <ModalManager
          close={this.props.toggleModal}
          {...this.props.modalProps}
        />
        <ImagePreviewManager {...this.props.imagePreviewProps} />
        <OverlayManager {...this.props.overlayProps} />
        <Router>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route
              exact
              path="/opportunity_invtations_action_page"
              component={opportunitiesResponsePage}
            />
            <Route
              exact
              path="/terms-and-conditions"
              component={TermAndConditions}
            />
            <Route
              exact
              path="/country-and-currency-selection"
              component={CountryAndCurrencySelection}
            />
            <Route exact path="/success" component={Success} />
            <AuthenticatedRoutes {...this.props} />
            {/* ------------------- THIS SHOULD BE THE LAST ROUTE AT ALL TIMES --------------_ */}
            <Route path="*" component={ErrorPage} />
          </Switch>
        </Router>
        <div style={{position:'fixed', bottom:5, right:20}}>
          <p style={{color:"grey", fontSize:'0.9rem'}}>v1.0.0</p>
        </div>
      </>
    );
  }
}
const AuthenticatedRoutes = (props) => {
  const params = window.location.href.toString().split("entry=")[1];
  const user = localStorage.userDeta
    ? JSON.parse(localStorage.getItem("userDeta"))
    : {};

  if (!params) {
    if (!localStorage.getItem("encoded")) {
      window.location.href = URLS.AUTH_URL;
    } else if (!user?.country && !user?.currency) {
      return <Redirect to="/country-and-currency-selection" />;
    } else if (!user?.has_accepted_tcs) {
      return <Redirect to="/terms-and-conditions" />;
    }
  }
  if (props.userData?.first_name) {
    props.getExchangeRates(getMyCurrencyInfo(props?.userData?.currency)?.code);
    return (
      <UserPageWrapper>
        <Switch>
          <Route exact path="/dashboard" component={UserDashboard} />
          <Route exact path="/partnerships" component={UserInvestmentWrapper} />
          <Route
            exact
            path="/partnerships/:id"
            component={InvestmentDetailWrapper}
          />
          <Route
            exact
            path="/partnerships/assets/:id"
            component={AssetDetail}
          />
          <Route
            exact
            path="/partnerships/invitation/:id"
            component={InvitationDetailPage}
          />
          <Route exact path="/wallets" component={WalletsWrapper} />
          <Route exact path="/settings" component={UserSettingsWrapper} />
          <Route
            exact
            path="/settings/update"
            component={EditSettingsWrapper}
          />
          <Route exact path="/reports" component={ReportsList} />
          <Route exact path="/reports/:id" component={ReportDetail} />
          <Route exact path="/settlements" component={SettlementsList} />
          <Route exact path="/settlements/:id" component={SettlementsDetail} />
          <Route
            exact
            path="/partnership/enterprise/:name"
            component={EnterpriseDetails}
          />
          <Route component={ErrorPage} />
        </Switch>
      </UserPageWrapper>
    );
  } else {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner variation={"TwoHalfCirclesType"} />
      </div>
    );
  }
};

const ModalManager = ({ show, props, close }) => {
  if (show)
    return (
      <Modal close={() => close()} {...props}>
        {props?.children}
      </Modal>
    );
  return <></>;
};
const NotificationManager = (props) => {
  if (props.show) return <Toast close={() => props.close()} {...props} />;
  return <></>;
};

const ImagePreviewManager = (props) => {
  if (props.show) return <ImagePreview {...props} />;
  return <></>;
};
const OverlayManager = (props) => {
  if (props.show) return <Overlay {...props} />;
  return <></>;
};
const mapStateToProps = (state) => {
  return {
    toastProps: state.toastProps,
    modalProps: state.modalProps,
    blanketProps: state.blanketProps,
    imagePreviewProps: state.imagePreviewProps,
    overlayProps: state.overlayProps,
    userData: state.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      toggleModal: toggleModalAction,
      toggleImagePreview: toggleImagePreviewAction,
      toggleLoadingBlanket,
      getMomoWallets: getUserMomoWalletsAction,
      getBankWallets: getUserBankWalletsAction,
      getAllInvestments: getAllInvestmentsAction,
      getUsersPaymentElections: listUsersPaymentElectionsAction,
      getUserInvitations: getUserInvitationsAction,
      getDashboardStats: userDashboardStatsAction,
      getOpportunityReports: getAllOpportunityReportsAction,
      getAllSettlements: getAllSettlementsAction,
      getuserInfo: getUserInfoAction,
      getExchangeRates: fetchExchangeRatesAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
