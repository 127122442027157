export const DO_NOTHING = "DO_NOTHING";
export const SHOW_SIDE_PANE = "SHOW_SIDE_PANE";


export const TOGGLE_TOAST = "TOGGLE_TOAST";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const LOADING_BLANKET = "TOGGLE_LOADING_BLANKET";
export const TOGGLE_OVERLAY = "TOGGLE_OVERLAY";


export const EDIT_SETTER = "EDIT_SETTER"
export const SET_CURRENT_ENTERPRISE = "SET_CURRENT_ENTERPRISE";
export const LOAD_MOMO_WALLETS = "LOAD_MOMO_WALLETS"; 
export const LOAD_BANK_WALLETS = "LOAD_BANK_WALLETS"; 
export const LOAD_INVESTMENTS = "LOAD_INVESTMENTS";
export const LOAD_USERS_PAYMENT_ELECTIONS = "LOAD_USERS_PAYMENT_ELECTIONS";
export const LOAD_USER_INVITATIONS = "LOAD_USER_INVITATIONS";
export const LOAD_USER_DASHBOARD_STATS = "LOAD_USER_DASHBOARD_STATS";

export const LOAD_OPPORTUNITY_REPORTS = "LOAD_OPPORTUNITY_REPORTS";
export const LOAD_OPPORTUNITY_SETTLEMENTS = "LOAD_OPPORTUNITY_SETTLEMENTS";
export const TOGGLE_IMAGE_PREVIEW = "TOGGLE_IMAGE_PREVIEW";
export const LOAD_USER_INFO = "LOAD_USER_INFO";
export const FETCH_EXCHANGE_RATES = "FETCH_EXCHANGE_RATES";