import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Table from "../../../../tools/components/v1/widgets/Table/Table";
import NoItems from "../../../../tools/components/v1/widgets/NoItems/NoItems";
import Header from "../../../../components/header/Header";
import { capitalizeMe, parseMe } from "../../../../shared/js/utils";
import { withRouter } from 'react-router-dom';

class InvestmentAssets extends Component {
  getActiveManager = (managers) => {
    if (!managers) return "No Manager";
    let activeManager = managers.find(
      (manager) =>
        parseMe(manager?.user?.info)?.status === "WORKING" || "PROBATION"
    );

    let name = activeManager
      ? capitalizeMe(activeManager?.user?.first_name) +
        " " +
        capitalizeMe(activeManager?.user?.last_name)
      : "No active manager";
    return {
      name: name,
      status: activeManager
        ? parseMe(activeManager?.user?.info)?.status
        : "Not set",
    };
  };

  handleShowDetail = (item) => {
    this.props.history.push("/partnerships/assets/" + item.id);
  };

  prepareTableData = (assets) => {
    return assets.map((asset) => {
      return [
        <p onClick={() => this.handleShowDetail(asset)}>{asset?.name}</p>,
        <p onClick={() => this.handleShowDetail(asset)}>{asset?.asset_type?.name}</p>,
        <p onClick={() => this.handleShowDetail(asset)}>{asset?.identification_number}</p>,
        <p onClick={() => this.handleShowDetail(asset)}>{this.getActiveManager(asset?.managers)?.name || "Not Set"}</p>,
        <p onClick={() => this.handleShowDetail(asset)}>{this.getActiveManager(asset?.managers)?.status || "Not Set"}</p>,
      ];
    });
  };
  render() {
    let assets = this.props?.investment?.opportunity?.assets;
    return (
      <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Header
          title="Opportunity Assets"
          subtitle="A list of assets linked to this opportunity"
        />
        {assets ? (
          <Table
            columns={[
              "Name",
              "Type",
              "Identification Number",
              "Manager",
              "Manager Status",
            ]}
            data={this.prepareTableData(assets)}
            hoverAnimation={false}
            rootClass="opp-assets-table"
          />
        ) : (
          <NoItems text="No Linked Assets" />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {

  return {
    investment: state.myInvestments?.filter(
      (investment) => investment.opportunity.id === localStorage.investmentId
    )[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvestmentAssets));
