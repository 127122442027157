import {
  SHOW_SIDE_PANE,
  DO_NOTHING,
  TOGGLE_TOAST,
  TOGGLE_MODAL,
  LOADING_BLANKET,
  EDIT_SETTER,
  LOAD_MOMO_WALLETS,
  LOAD_BANK_WALLETS,
  LOAD_INVESTMENTS,
  LOAD_USERS_PAYMENT_ELECTIONS,
  LOAD_USER_INVITATIONS,
  LOAD_USER_DASHBOARD_STATS,
  LOAD_OPPORTUNITY_REPORTS,
  LOAD_OPPORTUNITY_SETTLEMENTS,
  TOGGLE_IMAGE_PREVIEW,
  LOAD_USER_INFO,
  TOGGLE_OVERLAY,
  FETCH_EXCHANGE_RATES,
} from "./Constants/Constants";
import { getRandomStringKey } from "../../tools/components/v1/shared/js/utils";
import URLS from '../../shared/js/urls';
import ApiCallHandler from '../../shared/js/ApiCallHandler';




const currentUserProfile =  localStorage.userDeta ? JSON.parse(localStorage.userDeta) : {}
const MUTATION_FILTERS = {
  MOMO_WALLETS: LOAD_MOMO_WALLETS,
  BANK_WALLETS: LOAD_BANK_WALLETS,
  PAYMENT_ELECTIONS:LOAD_USERS_PAYMENT_ELECTIONS
};

export const doNothingFunction = (someDataToBePassed) => {
  return { type: DO_NOTHING, payload: someDataToBePassed };
};

export const getAllInvestmentsAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.LIST_PARTNERSHIPS,
      "POST",
      {user: currentUserProfile.id}
    );
    return dispatch({ type: LOAD_INVESTMENTS, payload: res.data || [] });
  };
};

export const getAllOpportunityReportsAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.GET_OPPORTUNITY_REPORTS,
      "POST",
      {user_id: currentUserProfile.id}
    );
    return dispatch({ type: LOAD_OPPORTUNITY_REPORTS, payload: res.data || [] });
  };
};

export const getAllSettlementsAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.GET_ALL_OPPORTUNITY_SETTLEMENTS,
      "POST",
      {user_id: currentUserProfile.id}
    );
    return dispatch({ type: LOAD_OPPORTUNITY_SETTLEMENTS, payload: res.data || [] });
  };
};


export const setEditSetterAction = (val) => {
  return { type: EDIT_SETTER, payload: val };
};


export const toggleSidePane = (load) => {
  return { type: SHOW_SIDE_PANE, payload: load };
};



export const toggleToastAction = (
  payload = { show: false, messsage: "Default Message" }
) => {
  return {
    type: TOGGLE_TOAST,
    payload: { key: getRandomStringKey(), ...payload },
  };
};
export const toggleModalAction = (payload = { show: false, props: {} }) => {
  return {
    type: TOGGLE_MODAL,
    payload: { key: getRandomStringKey(), ...payload },
  };
};
export const toggleLoadingBlanket = (payload = { show: false, props: {} }) => {
  return {
    type: LOADING_BLANKET,
    payload: payload,
  };
};

export const toggleImagePreviewAction = (payload = { show: false, props: {} }) => {
  return {
    type: TOGGLE_IMAGE_PREVIEW,
    payload: { key: getRandomStringKey(), ...payload },
  };
};
export const toggleOverlayAction = (payload = { show: false, props: {} }) => {
  return {
    type: TOGGLE_OVERLAY,
    payload: { key: getRandomStringKey(), ...payload },
  };
};
///////////////////////// ASSET CRUD ACTIONS BEGIN /////////////////////////////

export const createMutationAction = (filter, current_data, new_data) => {
  return function (dispatch) {
    if (current_data !== null && current_data !== undefined && current_data !== false){
      return dispatch({ type: MUTATION_FILTERS[filter], payload: [new_data, ...current_data] });
    } else {
      return dispatch({ type: MUTATION_FILTERS[filter], payload: [new_data]});
    }
  };
};


export const archiveMutationAction = (filter, current_data, item_to_delete_id) => {
  return function (dispatch) {
    return dispatch({ 
      type: MUTATION_FILTERS[filter], 
      payload: current_data.filter(data => data.id !== item_to_delete_id) 
    });
  };
};


export const editMutationAction = (filter, current_data, updated_item) => {

  let updated_data = current_data.filter(data => data.id !== updated_item.id)
  updated_data = [updated_item, ...updated_data]
  return function (dispatch) {
    return dispatch({ 
      type: MUTATION_FILTERS[filter], 
      payload: updated_data
    });
  };
};


///////////////////////// ASSET CRUD ACTIONS END ///////////////////////////////

export const getUserMomoWalletsAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.LIST_USERS_MOMO_WALLETS,
      "POST",
      { user_id: currentUserProfile.id }
    );
    return dispatch({ type: LOAD_MOMO_WALLETS, payload: res.data || [] });
  };
};
export const getUserBankWalletsAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.LIST_USERS_BANK_WALLETS,
      "POST",
      { user_id: currentUserProfile.id }
    );
    return dispatch({ type: LOAD_BANK_WALLETS, payload: res?.data?.length ? res?.data : [] });
  };
};
export const listUsersPaymentElectionsAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.LIST_ALL_USERS_PAYMENT_ELECTIONS,
      "POST",
      { user: currentUserProfile.id }
    );
    return dispatch({ type: LOAD_USERS_PAYMENT_ELECTIONS, payload: res.data || [] });
  };
};
export const getUserInvitationsAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.GET_USER_INVITATIONS,
      "POST",
      { user_id: currentUserProfile.id }
    );
    return dispatch({ type: LOAD_USER_INVITATIONS, payload: res.data || [] });
  };
};
export const userDashboardStatsAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.GET_DASHBOARD_STATS,
      "POST",
      { user_id: currentUserProfile.id }
    );
    return dispatch({ type: LOAD_USER_DASHBOARD_STATS, payload: res.data || [] });
  };
};


export const getUserInfoAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(URLS.GET_USERS, "POST", {
      id: "123e4567-e89b-12d3-a456-426614174000",
      email: currentUserProfile.email,
      action: "FIND_OR_CREATE",
    });
    return dispatch({ type: LOAD_USER_INFO, payload: res.data || {} });
  };
};
export const updateUserInfoAction = (payload) => {
  return {
    type: LOAD_USER_INFO,
    payload,
  };
};


export const fetchExchangeRatesAction = (code) => {
  return async function (dispatch) {
    const res = await ApiCallHandler.getWithoutBody(
      `${URLS.FETCH_EXCHANGE_RATES}${code}`
    );
    return dispatch({ type: FETCH_EXCHANGE_RATES, payload: res?.rates || {} });
  };
};
