import React, { Component } from "react";
import "./investmentCard.css";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { capitalizeMe, getMyCurrencyInfo, getRate } from "../../../shared/js/utils";
import { connect } from 'react-redux';

class InvestmentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  calculatesAvailableShares = (partnerships) => {
    let { investment } = this.props;

    if (partnerships && partnerships.length) {
      let totalShares = 0;
      partnerships?.forEach((partnership) => {
        totalShares += partnership?.shares;
      });
      return investment?.shares - totalShares;
    }

    return investment?.shares;
  };

  handleViewDetails = (investment) => {
    if (investment?.email) {
      this.props.history.push({
        pathname: `/partnerships/invitation/${investment?.id}`,
      });
    } else {
      localStorage.investmentId = investment?.opportunity?.id;
      this.props.history.push({
        pathname: `/partnerships/${investment?.opportunity?.id}`,
      });
    }
  };
  trancateTextBody = (text) => {
    let toShow = text?.split("</p>")[0];
    return toShow?.substring(0, 300) + "...";
  };

  handleViewEnterpriseDetail = (investment) => {
    this.props.history.push(
      "/partnership/enterprise/" + investment?.opportunity?.enterprise?.id
    );
  };

  showBadge = () => {
    let { investment } = this.props;
    if (investment?.is_approved === true) return;
    return (
      <div
        className={`inv-card-badge-container ${investment?.email && "pending"}`}
      >
        <p>
          {investment?.email
            ? "Pending Acceptance from you."
            : "Awaiting Admin Approval"}
        </p>
      </div>
    );
  };
  convertMoney = (money, code) => {
    let { rates } = this.props;
    let finalAmount = money / getRate(code, rates);
    return finalAmount;
  };
  render() {
    let { investment, user } = this.props;
    let enterpriseCurrency = getMyCurrencyInfo(
      investment?.opportunity?.enterprise?.currency
    );
    let userCurrency = getMyCurrencyInfo(user?.currency);
    return (
      <>
        <div
          id="invest-card-wrapper"
          style={{ cursor: "pointer", height: "max-content" }}
          className="invest-card-wrapper"
        >
          <div id="invest-card-wrapper">
            <div id="image-and-verify-container">
              <div id="com-image-container">
                <img
                  src={
                    investment?.opportunity?.enterprise?.logo ||
                    "https://i.pravatar.cc/300"
                  }
                  alt="  "
                />
              </div>

              <div
                id="company-name-container"
                onClick={() => this.handleViewEnterpriseDetail(investment)}
              >
                <span>
                  {capitalizeMe(investment?.opportunity?.enterprise?.name)}
                </span>
              </div>
            </div>
            <div
              id="name-and-desc-container"
              onClick={() => this.handleViewDetails(investment)}
            >
              <h3>{investment?.opportunity?.name}</h3>

              <div
                style={{ cursor: "pointer" }}
                className="investment-card-body"
                dangerouslySetInnerHTML={{
                  __html: this.trancateTextBody(investment?.opportunity?.about),
                }}
              ></div>
            </div>

            <div
              id="badge-root-wrapper"
              onClick={() => this.handleViewDetails(investment)}
            >
              {this.showBadge()}
            </div>
          </div>

          <div
            id="inest-money-container"
            onClick={() => this.handleViewDetails(investment)}
          >
            <div id="subscriptions-container">
              <div>
                <span>Shares</span>
                <p>{investment?.shares}</p>
              </div>
              <div>
                <span>Per Share</span>
                <p>
                  {" "}
                  {enterpriseCurrency?.symbol}{" "}
                  {investment?.opportunity?.price_per_share}(
                  {userCurrency?.symbol}{" "}
                  {this.convertMoney(
                    investment?.opportunity?.price_per_share,
                    enterpriseCurrency?.code
                  )?.toFixed(2)}
                  )
                </p>
              </div>

              <div id="duration-container">
                <span>Closes in</span>
                <p>
                  {
                    investment?.opportunity
                      ?.opportunity_running_period_in_months
                  }{" "}
                  months
                </p>
              </div>
            </div>

            <div id="return-percentages-container">
              <div>
                <span>Carry</span>
                <p>{investment?.opportunity?.carry} %</p>
              </div>
              <div>
                <span>Mgmt. Fee</span>
                <p>
                  {enterpriseCurrency?.symbol}{" "}
                  {investment?.opportunity?.mgmt_fee} ({userCurrency?.symbol}{" "}
                  {this.convertMoney(
                    investment?.opportunity?.mgmt_fee,
                    enterpriseCurrency?.code
                  )?.toFixed(2)}
                  )
                </p>
              </div>
              <div>
                <span>Admin Charges</span>
                <p>{investment?.opportunity?.admin_fee} %</p>
              </div>
            </div>
          </div>
          <div id="footer-btns-container">
            <div
              id="view-more-btn-container"
              onClick={() => this.handleViewDetails(investment)}
            >
              <p>View More</p>
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rates: state.exchangeRates,
    user:state.userData
  };
}

export default withRouter(connect(mapStateToProps, null)(InvestmentCard));
