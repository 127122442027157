/* eslint-disable import/no-anonymous-default-export */
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ApiCallHandler from "../shared/js/ApiCallHandler";
import URLS from "../shared/js/urls";
import {
  toggleModalAction,
  toggleToastAction,
  getAllInvestmentsAction,
  updateUserInfoAction,
  getUserInvitationsAction,
} from "../store/actions/actions";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../tools/components/v1/widgets/Button/Button";
import "./opportunityResponse.css";
import Title from "../components/title/Title";
import Spinner from "../tools/components/v1/widgets/Spinner/Spinner";

export default () => {
  const [dataGotten, setDataGotten] = useState({});

  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const encodedUserCredentials = window.location.href.split("entry=")[1];
    if (encodedUserCredentials) {
      let decoded = JSON.parse(atob(encodedUserCredentials));
      localStorage.encoded = decoded?.signIn;

      ApiCallHandler.send(URLS.GET_USERS, "POST", {
        id: "123e4567-e89b-12d3-a456-426614174000",
        email: decoded?.email,
        action: "FIND_OR_CREATE",
      }).then(({ data }) => {
        localStorage.userDeta = JSON.stringify({
          id: data?.id,
          email: data?.email,
        });

        dispatch(updateUserInfoAction(data));
      });
    }

    // eslint-disable-next-line no-unused-vars
    const params = window.location.href.toString().split("||");
    
    let id = "";

    if (location?.state?.id) {
      id = location.state.id;
    } else if (params && params[1]) {
      id = params[1];
    } else return;

    ApiCallHandler.send(URLS.GET_OPPORTUNITY_INVITATION_INFO, "POST", {
      id,
    }).then(({ data }) => {
      setDataGotten(data);
      // GETUSER STAKE IN THIS OPPORTUNITY
      if (data?.has_accepted) {
        history.push("/dashboard");
      }
    });
  }, [history, location, dispatch]);

  const handleAcceptOffer = async () => {
    const dataToSend = {
      shares: dataGotten?.shares,
      opportunity: dataGotten?.opportunity?.id,
      opportunity_invitation_id: dataGotten?.id,
      action: "CREATE_USER_AND_PARTNERSHIP",
    };
    const res = await ApiCallHandler.send(
      URLS.ACCEPT_INVITATION_PATH,
      "POST",
      dataToSend
    );

    if (res.success) {
      dispatch(toggleModalAction({ show: false }));
      dispatch(
        toggleToastAction({
          show: true,
          message: "Success",
          type: "success",
        })
      );
      dispatch(getAllInvestmentsAction());
      dispatch(getUserInvitationsAction());
      history.push("/dashboard");
    } else {
      dispatch(toggleModalAction({ show: false }));
      dispatch(
        toggleToastAction({
          show: true,
          message: "Acceptance Failed Please Try Again",
          type: "failure",
        })
      );
    }
  };

  const handleRejectOffer = async () => {
    const dataToSend = {
      id: dataGotten?.id,
      has_accepted: "False",
      has_responded: "True",
      is_archived: "True",
    };
    const res = await ApiCallHandler.send(
      URLS.UPDATE_OPPORTUNITY_INVITATION,
      "POST",
      dataToSend
    );

    if (res.success) {
      dispatch(toggleModalAction({ show: false }));
      dispatch(getUserInvitationsAction());
      dispatch(
        toggleToastAction({
          show: true,
          message: "Success",
          type: "success",
        })
      );
      history.push("/dashboard");
    } else {
      dispatch(toggleModalAction({ show: false }));
      dispatch(
        toggleToastAction({
          show: true,
          message: "Acceptance Failed Please Try Again",
          type: "failure",
        })
      );
    }
  };

  const showConfirmationMassage = () => {
    return dataGotten && dataGotten?.id ? (
      <div className="elevate-1 response-wrapper ">
        <center id="res-name-section">
          <Title>Hi {dataGotten?.name || " "},</Title>
        </center>
        <div id="response-intro-container">
          <p>
            You have been invited by{" "}
            <strong>{dataGotten?.opportunity?.enterprise?.name}</strong> and
            offered <strong>{dataGotten?.shares || " "} shares</strong> which
            will cost you{" "}
            <strong style={{ color: "green" }}>
              GHC{" "}
              {dataGotten?.shares * dataGotten?.opportunity?.price_per_share ||
                ""}{" "}
            </strong>
            In the opportunity with the details below
          </p>
        </div>

        <div id="res-about-container">
          <Title>{dataGotten?.opportunity?.name || ""}</Title>
          <div
            dangerouslySetInnerHTML={{
              __html: dataGotten?.opportunity?.about || "",
            }}
          ></div>
        </div>

        <div id="response-btns-container">
          <Button
            theme="danger"
            onClick={() => {
              dispatch(
                toggleModalAction({
                  show: true,
                  props: {
                    title: "Decline Invitation",
                    children: (
                      <div style={{ padding: "15px 10px" }}>
                        <p>
                          Do you wish to decline{" "}
                          <strong> {dataGotten?.opportunity?.name}</strong>{" "}
                          opportunity?.
                        </p>
                      </div>
                    ),
                    themeColor: "tomato",
                    cancel: true,
                    okay: {
                      text: "Decline",
                      function: () => handleRejectOffer(),
                    },
                  },
                })
              );
            }}
          >
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ fontSize: "2rem", color: "red" }}
            />
            Reject
          </Button>
          <Button
            theme="success"
            onClick={() => {
              dispatch(
                toggleModalAction({
                  show: true,
                  props: {
                    title: "Accept Invitation",
                    children: (
                      <div style={{ padding: "15px 10px" }}>
                        <p>
                          Accept{" "}
                          <strong> {dataGotten?.opportunity?.name}</strong>{" "}
                          opportunity.
                        </p>
                      </div>
                    ),
                    themeColor: "var(--app-theme)",
                    cancel: true,
                    okay: {
                      text: "Yes I Accept",
                      function: () => handleAcceptOffer(),
                    },
                  },
                })
              );
            }}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "2rem", color: "green" }}
            />
            Accept
          </Button>
        </div>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <Spinner />
      </div>
    );
  };

  return (
    <div
      style={{
        width: "50%",
        margin: "auto",
        paddingTop: "3%",
      }}
    >
      {showConfirmationMassage()}
    </div>
  );
};
