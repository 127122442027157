import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import Header from "../../../components/header/Header";
import NoItems from "../../../tools/components/v1/widgets/NoItems/NoItems";
import InvestmentCard from "../investment card/InvestmentCard";
import '../user-investments.css'



class MyInvestments extends Component {
  render() {
    const items = this.props.myInvestments;
    return (
      <div className="">
        <Header
          title="My Parterships"
          subtitle="A List of your Partnerships"
        ></Header>

        <div className="user-investment-items-wrapper">
          {items?.length > 0 ? (
            items.map((item) => <InvestmentCard inMyInvestments={true} investment={item}/>)
          ) : (
            <div id="no-investments-container">
              <NoItems title="No Partnerships" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state =>{
     return {
       myInvestments: state?.myInvestments && state?.myInvestments?.filter ? state.myInvestments?.filter(
         (investment) => investment.is_approved === true
       ) : [],
     };
} 

const mapDispatchToProps = dispatch =>{
     return bindActionCreators({},dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(MyInvestments);