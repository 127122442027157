import React, { Component } from "react";
import { connect } from "react-redux";
import "./user-investment-details.css";
import Title from "../../../../components/title/Title";
import { withRouter } from 'react-router-dom';
import { getMyCurrencyInfo } from "../../../../shared/js/utils";
class InvestmentDetails extends Component {

constructor(props) {
  super(props);
  this.state = {
    showDocs: false,
  };
}


  render() {
    let {investmentToShow:investment} = this.props;
    let currency =getMyCurrencyInfo(investment?.opportunity?.enterprise?.currency);
    return (
      <div className="user-investment-details-container">
        <div className="comp-and-inv-info-container">
          <div className="comp-logo-namecontainer">
            <img
              src={
                investment?.opportunity?.enterprise?.logo ||
                "https://i.pravatar.cc/300"
              }
              alt="company logo"
            />
            <div>
              <span>Partnership From </span>
              <h3>{investment?.opportunity?.enterprise?.name}</h3>
            </div>
          </div>
          <div id="inv-info-container" style={{ marginTop: "2rem" }}>
            <Title>{investment?.opportunity?.name}</Title>

            <div
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: investment?.opportunity?.about,
              }}
            ></div>
          </div>
        </div>

        <div className="inv-and-docs-container">
          <div className="elevate-float inv-detail-container">
            <p id="inv-detail-header">PARTNERSHIP DETAILS</p>

            <div id="info-stats-container">
              <span>Closing Date</span>
              <p>{investment?.opportunity?.close_date}</p>
            </div>
            <div id="info-stats-container">
              <span>Runs For</span>
              <p>
                {investment?.opportunity?.opportunity_running_period_in_months}{" "}
                months
              </p>
            </div>
            <div id="info-stats-container">
              <span>Total Shares</span>
              <p>{investment?.opportunity?.shares}</p>
            </div>
            <div id="info-stats-container">
              <span>Allocated Shares</span>
              <p>{investment?.shares}</p>
            </div>

            <div id="info-stats-container">
              <span>Price Per Share</span>
              <p>{currency?.symbol}{" "} {investment?.opportunity?.price_per_share}</p>
            </div>
            <div id="info-stats-container">
              <span>Admin charges</span>
              <p>{investment?.opportunity?.admin_fee}% </p>
            </div>
            <div id="info-stats-container">
              <span>Management Charges</span>
              <p>{currency?.symbol}{" "} {investment?.opportunity?.mgmt_fee}</p>
            </div>
            <div id="info-stats-container">
              <span>Carry</span>
              <p>{investment?.opportunity?.carry} %</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    investmentToShow: state.myInvestments?.filter(
      (investment) => investment.opportunity.id === localStorage.investmentId
    )[0],
    user: state.userData,
  };
};


export default withRouter(connect(mapStateToProps, null)(InvestmentDetails));
