import React from "react";
import FormGenerator from "../../../tools/components/v1/FormGenerator/FormGenerator";
import Fields from "../../../tools/components/v1/FormGenerator/Fields";
import { removeCountriesWithEmptyCurency } from "../../../shared/js/utils";
import "./style.css";
import ApiCallHandler from '../../../shared/js/ApiCallHandler';
import URLS from '../../../shared/js/urls';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserInfoAction } from '../../../store/actions/actions';
import { useHistory } from 'react-router-dom';

export default function CountryAndCurrencySelection() {
    const dispatch = useDispatch();
      const history = useHistory();

    let user=  useSelector((state) => state.userData);
    const onSubmit = (data, reset) => {
        let toSend  ={}
        toSend.country = data?.country?.name;
        toSend.currency = `${data?.currency?.name}-${data?.currency?.currency?.name}`;
        toSend.id =  user?.id

        ApiCallHandler.send(URLS.UPDATE_USERS, "POST", toSend).then(res => {
            dispatch(updateUserInfoAction(res?.data[0]));
            localStorage.userDeta = JSON.stringify({
              email: res?.data[0]?.email,
              id: res?.data[0]?.id,
              country: res?.data[0]?.country,
              currency: res?.data[0]?.currency,
              has_accepted_tcs: res?.data[0]?.has_accepted_tcs,
            });
            reset();
            history.push("/dashboard");
        })

  };

  const countrySearch = (value) => {
    const filtered = removeCountriesWithEmptyCurency().filter((item) => {
      const arr = item?.name.toLowerCase().split(value?.toLowerCase())  ;
      if (arr.length > 1) return item;
      return null;
    });
    return filtered;
  };
  const currencySearch = (value) => {
    const filtered = removeCountriesWithEmptyCurency().filter((item) => {
      const arr = item?.currency?.name?.toLowerCase().split(value?.toLowerCase())  ;
      if (arr.length > 1) return item;
      return null;
    });
    return filtered;
  };


  return (
    <div className="country-and-currency-root">
      <div className="elevate-float country-form-container">
        <div className="country-currency-notice">
          <h3>NOTE </h3>
          <p>
            The Information You provide here can't be changed. Make sure to
            choose the correct one.
          </p>
        </div>
        <FormGenerator
          elevation={0}
          fields={[
            {
              data: removeCountriesWithEmptyCurency(),
              fieldType: Fields.DROPDOWN,
              type: "full",
              name: "country",
              label: "Country",
              placeholder: "Select Country",
              required: true,
              labelExtractor: (item) => item.name?.toUpperCase(),
              valueExtractor: (item) => item.name,
               onSearch:(value) => countrySearch(value)
            },
            {
              data: removeCountriesWithEmptyCurency(),
              fieldType: Fields.DROPDOWN,
              type: "full",
              name: "currency",
              label: "Currency",
              placeholder: "Select Currency",
              required: true,
              labelExtractor: (item) =>
                `${item.name?.toUpperCase()} - ${item?.currency?.name?.toUpperCase()} (${
                  item?.currency?.symbol
                })`,
                   onSearch:(value) =>currencySearch(value)
            },
          ]}
          onSubmit={onSubmit}
          formSubmitBtnText="Save Information"
          subtitle=""
          title=""
        />
      </div>
    </div>
  );
}
