import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Header from "../../../components/header/Header";
import NoItems from "../../../tools/components/v1/widgets/NoItems/NoItems";
import InvestmentCard from "../investment card/InvestmentCard";
import "../user-investments.css";

class MyInvitations extends Component {
  render() {
    const items = this.props.invitations;
    return (
      <div className="">
        <Header
          title="Invitations"
          subtitle="A List of Opportunities you have been invited to"
        ></Header>

        <div className="user-investment-items-wrapper">
          {items?.length > 0 ? (
            items.map((item) => <InvestmentCard investment={item} fromInvitation={true} />)
          ) : (
            <div id="no-investments-container">
              <NoItems title="No Investments" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const freshInvites = state.invitations?.filter(item=>item?.has_accepted === null);
  const acceptedInvites = state.myInvestments?.filter(
    (investment) => investment.is_approved === null
  );

  return {
    invitations: [...freshInvites, ...acceptedInvites],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MyInvitations);
