import {
  faCog,
  faDatabase,
  faMoneyBillAlt,
  faNewspaper,
  faPiggyBank,
  faSignOutAlt,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";



export const MENU = [
  {
    groupName: "Manage",
    children: [
      {
        id: "dashboard",
        name: "Dashboard",
        icon: faDatabase,
        link: "/dashboard",
      },
      {
        id: "investments",
        name: "Partnerships",
        icon: faPiggyBank,
        link: "/partnerships",
      },
      {
        id: "updates",
        name: "Reports",
        icon: faNewspaper,
        link: "/reports",
      },
      {
        id: "settlements",
        name: "Settlements",
        icon: faMoneyBillAlt,
        link: "/settlements",
      },
    ],
  },
];

export const BOTTOM_MENU = [
  {
    id: "wallets",
    name: "Wallets",
    icon: faWallet,
    link: "/wallets",
  },
  {
    id: "settings",
    name: "Settings",
    icon: faCog,
    link: "/settings",
  },
  {
    id: "signout",
    name: "Sign Out",
    icon: faSignOutAlt,
    onClick: () => {
      localStorage.clear();
    },
    link: "/",
  },
];



export const BAR_DATA = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "April",
    "May",
    "June",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Monthly Earnings",
      data: [0, 0, 0, 0, 0, 0,0, 0,0,0,0, 0],
      backgroundColor: ["green", "green", "green", "green", "green", "green"],
      borderWidth: 1,
    },
  ],
};