import React, { Component } from "react";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faMailBulk } from "@fortawesome/free-solid-svg-icons";
import Verified from '../../shared/components/verified/Verified';
import ApiCallHandler from '../../shared/js/ApiCallHandler';
import URLS from '../../shared/js/urls';
import {
  faFacebookSquare,
  faInstagramSquare,
  faLinkedin,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import './EnterpriseDetails.css'

class EnterpriseDetails extends Component {

    constructor(props){
        super(props);
        this.state={
            enterprise:{}
        }
    }

 componentDidMount(){
     let id = window.location.pathname.split('/')[3];
        ApiCallHandler.send(URLS.GET_ENTERPRISES, "POST", {
            id: id
        }).then(res => this.setState({enterprise: res.data}));
    }
  showProfileInfo = () => {
    let { enterprise } = this.state;
    return (
      <div id="profile-info-container">
        <div id="img-info-container">
          <div id="profile-img-container" style={{ position: "relative" }}>
            <img src={enterprise?.logo} id="profile-img" alt="  " />
          </div>
          <div id="info-container">
            <h3>
              {enterprise?.name}
              {enterprise?.is_verified ? (
                <Verified bg={"green"} height={"25px"} width={"25px"} />
              ) : null}
            </h3>
            {enterprise?.contacts && (
              <p>
                <FontAwesomeIcon icon={faMailBulk} />{" "}
                {enterprise?.contacts?.primary_email || ""}
              </p>
            )}
            {this.renderSocialMedia(enterprise?.contacts)}
          </div>
        </div>
      </div>
    );
  };

  openLink = (link) => {
    window.open(link, "_blank");
  };

  renderSocialMedia = (contacts) => {
    if (contacts !== null) {
      return (
        <>
          <div id="social-media-container">
            {contacts?.website && (
              <FontAwesomeIcon
                icon={faGlobe}
                onClick={() => this.openLink(contacts?.website)}
              />
            )}
            {contacts?.twiter && (
              <FontAwesomeIcon
                icon={faTwitterSquare}
                onClick={() => this.openLink(contacts?.twitter)}
              />
            )}
            {contacts?.facebook && (
              <FontAwesomeIcon
                icon={faFacebookSquare}
                onClick={() => this.openLink(contacts?.facebook)}
              />
            )}
            {contacts?.instagram && (
              <FontAwesomeIcon
                icon={faInstagramSquare}
                onClick={() => this.openLink(contacts?.instagram)}
              />
            )}
            {contacts?.linkedin && (
              <FontAwesomeIcon
                icon={faLinkedin}
                onClick={() => this.openLink(contacts?.linkedin)}
              />
            )}
          </div>
        </>
      );
    }
  };

  render() {
    let { enterprise } = this.state;
    return (
      <div style={{ width: "90%", margin: "auto", marginTop: "1rem" }}>
        {this.showProfileInfo()}

        <div style={{ marginTop: "2rem" }}>
          <div id="biography-conatiner">
            <h3>About</h3>

            <div
              dangerouslySetInnerHTML={{
                __html: enterprise?.bio
                  ? enterprise?.bio
                  : "No biography added",
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}


export default connect(null, null)(EnterpriseDetails);
