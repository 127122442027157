
import React, { Component } from 'react'
import { getRandomStringKey, removeCountriesWithEmptyCurency } from '../../../../shared/js/utils'
import './bank-accounts.css'





export default class VerifyBankInfo extends Component {

    constructor(props) {
    super(props);
    this.state = {
      data: {},
      key: getRandomStringKey(),
    };
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        data: this.props.data,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props) {
      return {
        data: props.data,
        key: getRandomStringKey(),
      };
    }
    return null;
  }
  extractCountry = (country) => {
    if (!country) return {};
    if(typeof country === 'string'){
    let _country = country?.split("-");
    return removeCountriesWithEmptyCurency().find(
      (item) => item?.name === _country[0])
    }
    return country;
  }

  render() {
    let {data} = this.state;
  return (
    <div key={this.state.key}>
      <div className="verify-bank-wrapper">
        {/* user info */}
        <div className="verify-item">
          <span>Account Name</span>
          <p>{data?.account_name}</p>
        </div>
        <div className="verify-item">
          <span>Account Holder's Address</span>
          <p>{data?.holder_address}</p>
        </div>

        {/* bank info */}

        <div className="verify-item">
          <span>Bank Country</span>
          <p>{this.extractCountry(data?.country)?.name}</p>
        </div>
        <div className="verify-item">
          <span>Account Currency</span>
          <p>
            {this.extractCountry(data?.currency)?.currency?.name} -{" "}
            {this.extractCountry(data?.currency)?.currency?.code}
          </p>
        </div>
        <div className="verify-item">
          <span>Bank Name</span>
          <p>{data?.bank_name}</p>
        </div>
        <div className="verify-item">
          <span>Account Number</span>
          <p>{data?.account_number}</p>
        </div>
        <div className="verify-item">
          <span>Bank Address</span>
          <p>{data?.bank_address}</p>
        </div>
        <div className="verify-item">
          <span>Bank Branch</span>
          <p>{data?.bank_branch}</p>
        </div>
        <div className="verify-item">
          <span>Sort Code</span>
          <p>{data?.sort_code}</p>
        </div>
        <div className="verify-item">
          <span>Swift Code</span>
          <p>{data?.swift_code}</p>
        </div>
      </div>
    </div>
  );
  }
}
