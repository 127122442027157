import { ALL_COUNTRIES } from './countries';
export const NAME = "____name____";
export const OBJECT_OBJECT = "[object Object]";
export const VALUE = "value";
export const LABEL = "label";
export const isArrayOfObjects = (data) => {
  if (!data) return false;
  const one = data[0];
  if (typeof one === "object") return true;
  return false;
};
export const lowKeyValidation = (props) => {
  const { data, valueExtractor, labelExtractor } = props;
  if (!data)
    console.error(
      "You have not provided any 'data'. 'data' should be an array of [string || object]"
    );

  const elemsAreObjs = isArrayOfObjects(data);
  if (elemsAreObjs && !labelExtractor)
    console.error(
      "[labelExtractor] Provide a function that returns parts of the object you would like to display as the checkbox label... "
    );

  if (elemsAreObjs && !valueExtractor)
    console.warn(
      "[vaueExtractor] Provide a function that returns parts of the object you would like to return as value onChange for each checkbox..."
    );
};

/**
 *
 * Convert a base64 String back to a file object
 * @param {base64String} base64String
 * @param {String} filename
 * @returns {File} image File Object
 *
 */
export const base64StringtoFile = (
  base64String,
  filename = "filename-" + getRandomStringKey()
) => {
  var arr = base64String.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const getValueOrLabel = (item, key, props) => {
  const { labelExtractor, valueExtractor, data } = props;
  const isObjects = isArrayOfObjects(data);
  if (key === LABEL && labelExtractor && isObjects) return labelExtractor(item);
  if (key === VALUE && valueExtractor && isObjects) return valueExtractor(item);
  return (item || "...").toString();
};

export const getRandomStringKey = (limit = 9999999) => {
  return Math.random(limit).toString();
};

export const stripItemFromArray = (value, comparisonFieldName, array) => {
  if (!array) return [];
  var rest = [];
  const found = array.filter((item) => {
    var isItem = item[comparisonFieldName] === value;
    if (!isItem) rest.push(item);
    return isItem;
  })[0];

  return [found, rest];
};


export const getFileType = (file) => {
  if (!file) return;
  const _file = file.split("?");
  return _file[0].substr(_file.length - 5);
};

export const convertNumberToShortForm = (number=0) => {
  var pow = Math.pow,
    floor = Math.floor,
    abs = Math.abs,
    log = Math.log;
  var abbrev = [" K", "M", " B", "T"];

  function round(n, precision) {
    var prec = Math.pow(10, precision);
    return Math.round(n * prec) / prec;
  }

  var base = floor(log(abs(number)) / log(1000));
  var suffix = abbrev[Math.min(2, base - 1)];
  base = abbrev.indexOf(suffix) + 1;
  return suffix ? round(number / pow(1000, base), 2) + suffix : "" + number;
};

export const getMeaningfulDate = (date) => {
  const today = new Date();
  let newDate = date ? new Date(date) : today;
  let options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  
  return Intl.DateTimeFormat("en-US", options).format(newDate);
};



export const validatePhoneNumber = (phoneNumber) => {
  // eslint-disable-next-line
  const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
  return regex.test(phoneNumber);
}

export const parseMe = (stringified) => {
  if (typeof stringified === "string") return JSON.parse(stringified);
  return stringified;
};


export const removeCountriesWithEmptyCurency = () => {
  const countries = ALL_COUNTRIES.filter((country) => country.currency.symbol);
  return countries;
};


export const getMyCurrencyInfo = (name)=>{
  if(!name){
    name = "Ghana";
  }

  let arr = name?.split('-')
  if(arr?.length <=1){
    arr = ['Ghana'];
  }
  let country = ALL_COUNTRIES?.find(country=>country.name === arr[0])
  return country?.currency

}

export const getRate = (code = "GHS", rates={})=>{
  let rate = rates[code]
  if(!rate){
    return 1
  }
  return rate

}

export const isEmpty = (value) => {
  if (
    value === undefined ||
    value === null ||
    value === "" ||
    value === [] ||
    value === "null" ||
    value === "undefined" ||
    Object?.keys(value)?.length === 0 ||
    value === {}
  )
    return true;
  return false;
};


export const capitalizeMe = (string) => {
return string?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
}


  export const  onCurrencySearch = (value) => {
     const filtered = removeCountriesWithEmptyCurency().filter((item) => {
       const arr = item?.currency?.name
         ?.toLowerCase()
         .split(value?.toLowerCase());
       if (arr.length > 1) return item;
       return null;
     });
     return filtered;
   };
   export const onCountrySearch = (value) => {
     const filtered = removeCountriesWithEmptyCurency().filter((item) => {
       const arr = item?.name.toLowerCase().split(value?.toLowerCase());
       if (arr.length > 1) return item;
       return null;
     });
     return filtered;
   };