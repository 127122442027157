import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./ReportCard.css";
import Title from "../../../components/title/Title";
import { withRouter } from "react-router-dom";
import { getMeaningfulDate } from "../../../shared/js/utils";
import { toggleImagePreviewAction } from "../../../store/actions/actions";
import { IMAGE_TYPES, VIDEO_TYPES } from "../../../shared/js/Konstants";
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ReportCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newReport: {},
    };
  }

  getFileTyle = (file) => {
    if(!file) return;
    const _file = file.split("?");
    return _file[0].substr(_file.length - 5);
  };
  getFileName = (file) => {
    if(!file) return ;
    let arr = file?.split("/");
    let last = arr?.[arr?.length - 1]?.split("?");
    return last[0];
  };
  showMedia = (file) => {
    if (!file) return;
    let type = this.getFileTyle(file);

    if (IMAGE_TYPES.includes(type))
      return (
        <div id="report-card-body-images">
          <img
            src={file}
            alt="  "
            onClick={() => {
              this.props.toggleImagePreview({
                show: true,
                image: file,
              });
            }}
          />
        </div>
      );

    if (VIDEO_TYPES.includes(type))
      return (
        <div id="report-card-body-images">
          <video src={file} controls />
        </div>
      );

    return (
      <div className="comment-file-container ">
        <div
          className="elevate-float file-wrapper"
          onClick={() => window.open(file, "_blank")}
        >
          <FontAwesomeIcon icon={faFileAlt} fontSize="7rem" color="maroon" />
        </div>
        <p
          style={{ color: "blue" }}
          onClick={() => window.open(file, "_blank")}
        >
          {this.getFileName(file)}
        </p>
      </div>
    );
  };

  trancateTextBody = (text) => {
    if (text === "undefined") return "";
    let toShow = text?.split("</p>")[0];

    if (toShow.length > 300) return toShow?.substr(0, 300) + "...";
    return toShow;
  };

  viewDetails = (report) => {
    localStorage.report_id = report?.id;
    this.props.history.push(`/reports/${report?.title?.replace(/\s/g, "-")}`);
  };
  render() {
    let { report } = this.props;
    return (
      <div className="report-card-root elevate-float">
        <div id="report-card-logo-and-name-container">
          <div id="report-card-logo-container">
            <img
              src={
                report?.opportunity?.enterprise?.logo ||
                "https://i.pravatar.cc/300"
              }
              alt="  "
            />
          </div>

          <div id="report-card-opp-det">
            <h2>{report?.opportunity?.name}</h2>
            <p>{report?.opportunity?.enterprise?.name}</p>
            <small>{getMeaningfulDate(report?.date)}</small>
          </div>
        </div>

        <div className="report-card-body-container report-card-body">
          <div
            id="report-card-title-container"
            onClick={() => this.viewDetails(report)}
          >
            <Title>{report?.title || ""}</Title>
          </div>

          <div
            className="report-card-body-container"
          >
            <div
              style={{ cursor: "pointer" }}
              onClick={() => this.viewDetails(report)}
              className="tweet-card-content optimum-padding-right-left"
              dangerouslySetInnerHTML={{
                __html: this.trancateTextBody(report?.body),
              }}
            ></div>
          </div>
          {this.showMedia(report?.file)}
        </div>
        {/* Will be needed when report commenting is added */}
        
        {/* <div id="report-card-footer-container">
          <div
            className="elevate-flot comment-btn-container"
            onClick={() => alert("hello")}
          >
            <FontAwesomeIcon icon={faComment} />
            <span>Comment </span>
          </div>
        </div> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    toggleImagePreview:toggleImagePreviewAction
  }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportCard)
);
