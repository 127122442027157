import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "../wallets.css";
import bank from "../../../shared/imgs/bank.svg";
import {
  archiveMutationAction,
  toggleModalAction,
  setEditSetterAction,
  toggleToastAction,
  editMutationAction
} from "../../../store/actions/actions";
import NoItems from "../../../tools/components/v1/widgets/NoItems/NoItems";
import URLS from "../../../shared/js/urls";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import Header from "../../../components/header/Header";
import Title from "../../../components/title/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faPen, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { getMyCurrencyInfo, isEmpty } from "../../../shared/js/utils";
import CreateBankAccount from './CreateBankAccount';
import VerifyBankInfo from "./steps/VerifyBankInfo";
class BankWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: {},
      showDelete: false,
      toDeleteId: "",
    };
  }
  handleShow = wallet=>{
        this.props.toggleModal({
          show: true,
          props: {
            title: `${wallet?.account_name} Information`,
            children: (
              <div style={{margin:'15px 20px'}}>
                <VerifyBankInfo data={wallet} />
              </div>
            ),
            themeColor: "var(--app-theme)",
            cancel: false,
            size: "medium",
          },
        });
  }

  close = () => {
    this.props.toggleModal({
      show: false,
    });
  };

  showBankWallets = (wallet) => {
    let currency = getMyCurrencyInfo(wallet.currency);

    return (
      <div id="bank-item-container" className="elevate-float">
        <div className="bank-icon-container icon-container ">
          <img src={bank} alt="bank" />
        </div>

        <div id="bank-wallet-info-container">
          <div onClick={() => this.handleShow(wallet)}>
            <h3>{wallet?.account_name?.toUpperCase()}</h3>
            <p id="bank-name">
              {wallet?.bank_name} ({wallet?.bank_branch})
            </p>
            <p style={{ fontWeight: "bold" }}>{wallet?.country}</p>
            <span>Swift - {wallet?.swift_code}</span>
            <p id="account-number" className="account-num">
              {wallet?.account_number} ({currency?.code})
            </p>
          </div>

          {this.state.showDelete && wallet?.id === this.state.toDeleteId ? (
            <div className="delete-container elevate-float">
              <div onClick={() => this.handleAdd(wallet)}>
                <FontAwesomeIcon icon={faPen} /> Edit Wallet info
              </div>
              <div onClick={() => this.handleDelete(wallet)}>
                <FontAwesomeIcon icon={faTrashAlt} /> Delete Wallet
              </div>
            </div>
          ) : null}

          <div
            className="more-container"
            onClick={() => this.showDeleteItem(wallet.id)}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </div>
        </div>
      </div>
    );
  };

  handleAdd = (editData = null) => {
    this.setState({ showDelete: false });
    this.props.toggleModal({
      show: true,
      props: {
        title: `${editData ? "Edit" : "Add New"} Bank Account`,
        children: this.form(editData),
        themeColor: "var(--app-theme)",
        cancel: false,
        size: "large",
      },
    });
  };

  showDeleteItem = (id = null) => {
    let { showDelete } = this.state;
    if (showDelete) {
      this.setState({ showDelete: false, toDeleteId: null });
    } else {
      this.setState({ showDelete: true, toDeleteId: id });
    }
  };

  handleDelete = (item) => {
    let { wallets } = this.props;
    if (wallets?.length < 1) return;
    this.setState({ showDelete: false });
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.account_number}"`,
        children: `Are you sure you want to delete "${item?.account_number}"`,
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => {
            ApiCallHandler.send(URLS.ARCHIVE_BANK_WALLETS, "POST", {
              id: item?.id,
            }).then(() => {
              this.props.archiveRecord(wallets, item?.id);
            });
          },
        },
      },
    });
  };

  form = (editData = {}) => {
    return <CreateBankAccount editData={editData} close={this.close} />;
  };

  render() {
    let { wallets } = this.props;
    return (
      <div id="bank-wallet-wrapper">
        <div id="header-txt-and-add-container">
          <div style={{ marginLeft: "20px" }}>
            <Title className="text-toggler" onClick={() => this.handleAdd()}>
              <FontAwesomeIcon icon={faPlus} /> New Bank Account
            </Title>
          </div>
        </div>

        <Header title="Bank Accounts" subtitle="" />
        <div id="bank-wallet-list-container">
          {!isEmpty(wallets) ? (
            wallets?.map((wallet) => this.showBankWallets(wallet))
          ) : (
            <div>
              <NoItems text="No Bank Account Found.Click the button." />
            </div>
          )}
        </div>
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return {
    wallets: state.bankWallets,
    userData:state.userData
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setEditPayload: (val) => dispatch(setEditSetterAction(val)),
      toggleModal: toggleModalAction,
      toggleToast: toggleToastAction,
      editRecord: (current_data, new_data) =>
        dispatch(editMutationAction("BANK_WALLETS", current_data, new_data)),
      archiveRecord: (current_data, data_to_delete_id) =>
        dispatch(
          archiveMutationAction("BANK_WALLETS", current_data, data_to_delete_id)
        ),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BankWallet);
