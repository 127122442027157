import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "../investment details/tab-items/user-investment-details.css";

import { withRouter } from "react-router-dom";
import Title from "../../../components/title/Title";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import {
  toggleToastAction,
  toggleModalAction,
  getAllInvestmentsAction,
  getUserInvitationsAction,
  toggleOverlayAction,
} from "../../../store/actions/actions";
import URLS from "../../../shared/js/urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../../tools/components/v1/widgets/Spinner/Spinner";
import { getMyCurrencyInfo, getRate } from '../../../shared/js/utils';

class InvitationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  RESPONSE_TYPES = {
    ACCEPT: "ACCEPT",
    DECLINE: "DECLINE",
  };

  onAcceptInvitation = async (invitation) => {
    this.props.toggleLoader({
      show: true,
      component: <Spinner variation={"TwoHalfCirclesType"} />,
    });

    const dataToSend = {
      shares: invitation?.shares,
      opportunity: invitation?.opportunity.id,
      opportunity_invitation_id: invitation?.id,
      action: "CREATE_USER_AND_PARTNERSHIP",
    };
    let res = await ApiCallHandler.send(
      URLS.ACCEPT_INVITATION_PATH,
      "POST",
      dataToSend
    );

    if (res) {
      this.props.toggleLoader({ show: false });
      this.showResponse(res);
    }
  };

  onDeclineInvitation = (invitation) => {
    this.props.toggleLoader({
      show: true,
      component: <Spinner variation={"TwoHalfCirclesType"} />,
    });
    const dataToSend = {
      id: invitation?.id,
      has_accepted: "False",
      has_responded: "True",
      is_archived: "True",
    };
    ApiCallHandler.send(
      URLS.UPDATE_OPPORTUNITY_INVITATION,
      "POST",
      dataToSend
    ).then((res) => {
      if (res) {
        this.props.toggleLoader({ show: false });
        this.showResponse(res, this.RESPONSE_TYPES.DECLINE);
      }
    });
  };

  showResponse = (res, type = this.RESPONSE_TYPES.ACCEPT) => {
    if (res && res.success) {
      this.props.getInvestments();
      this.props.getInvitations();
      this.props.history.push("/partnerships");
      this.props.toggleToast({
        show: true,
        message: `${
          type === this.RESPONSE_TYPES.DECLINE ? "Declined" : "Acceptance"
        } Successful`,
        type: "success",
      });
    } else {
      this.props.toggleToast({
        showToast: true,
        message: "An error occured. Try again",
        type: "failure",
      });
    }
  };

  showConfirmationModal = (item, type) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Invitation Acceptance Confirmation`,
        children: (
          <div style={{ padding: "10px 15px" }}>
            Do you wish to{" "}
            {type === this.RESPONSE_TYPES.ACCEPT ? "accept" : "decline"} this
            opportunity invitation from
            {item?.opportunity?.enterprise?.name} ?
          </div>
        ),
        themeColor: "var(--app-theme)",
        cancel: true,
        okay: {
          text: "Yes",
          function: () =>
            type === this.RESPONSE_TYPES.ACCEPT
              ? this.onAcceptInvitation(item)
              : this.onDeclineInvitation(item),
        },
      },
    });
  };
  convertMoney = (money, code) => {
    let { rates } = this.props;
    let finalAmount = money / getRate(code, rates);
    return finalAmount;
  };
  render() {
    let { invitation, user} = this.props;
    let enterpriseCurrency = getMyCurrencyInfo(invitation?.opportunity?.enterprise?.currency );
    let userCurrency = getMyCurrencyInfo(user?.currency );

    return (
      <div style={{ width: "95%", margin: "auto", position: "relative" }}>
        <div className="user-investment-details-container">
          <div className="comp-and-inv-info-container">
            <div className="comp-logo-namecontainer">
              <img
                src={
                  invitation?.opportunity?.enterprise?.logo ||
                  "https://i.pravatar.cc/300"
                }
                alt="company logo"
              />
              <div>
                <span>Invitation From </span>
                <h3>{invitation?.opportunity?.enterprise?.name}</h3>
              </div>
            </div>

            <div id="inv-info-container" style={{ marginTop: "2rem" }}>
              <Title>{invitation?.opportunity?.name}</Title>
              <div
                style={{ cursor: "pointer" }}
                dangerouslySetInnerHTML={{
                  __html: invitation?.opportunity?.about,
                }}
              ></div>
            </div>
          </div>

          <div className="inv-and-docs-container">
            <div className="elevate-float inv-detail-container">
              <p id="inv-detail-header">PARTNERSHIP DETAILS</p>

              <div id="info-stats-container">
                <span>Closing Date</span>
                <p>{invitation?.opportunity?.close_date}</p>
              </div>
              <div id="info-stats-container">
                <span>Runs For</span>
                <p>
                  {
                    invitation?.opportunity
                      ?.opportunity_running_period_in_months
                  }{" "}
                  months
                </p>
              </div>
              <div id="info-stats-container">
                <span>Total Shares</span>
                <p>{invitation?.opportunity?.shares}</p>
              </div>
              <div id="info-stats-container">
                <span>Allocated Shares</span>
                <p>{invitation?.shares}</p>
              </div>
              <div id="info-stats-container">
                <span>Price Per Share</span>
                <p>
                  {enterpriseCurrency?.symbol}{" "}
                  {invitation?.opportunity?.price_per_share}(
                  {userCurrency?.symbol}{" "}
                  {this.convertMoney(
                    invitation?.opportunity?.price_per_share,
                    enterpriseCurrency?.code
                  )?.toFixed(2)}
                  )
                </p>
              </div>
              <div id="info-stats-container">
                <span>Admin charges</span>
                <p>{invitation?.opportunity?.admin_fee}% </p>
              </div>
              <div id="info-stats-container">
                <span>Management Charges</span>
                <p>
                  {enterpriseCurrency?.symbol}{" "}
                  {invitation?.opportunity?.mgmt_fee} ({userCurrency?.symbol}{" "}
                  {this.convertMoney(
                    invitation?.opportunity?.mgmt_fee,
                    enterpriseCurrency?.code
                  )?.toFixed(2)}
                  )
                </p>
              </div>
              <div id="info-stats-container">
                <span>Carry</span>
                <p>{invitation?.opportunity?.carry} %</p>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div
                className="elevate-float accept-item response-btns"
                onClick={() =>
                  this.showConfirmationModal(
                    invitation,
                    this.RESPONSE_TYPES.ACCEPT
                  )
                }
                style={{ marginRight: "15px" }}
              >
                <FontAwesomeIcon icon={faCheck} />
                <p>Accept </p>
              </div>
              <div
                className="elevate-float reject response-btns"
                onClick={() =>
                  this.showConfirmationModal(
                    invitation,
                    this.RESPONSE_TYPES.DECLINE
                  )
                }
              >
                <FontAwesomeIcon icon={faTimes} />
                <p>Decline</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const id = window.location.pathname.split("/")[3];
  return {
    invitation: state.invitations?.filter((item) => item.id === id)[0],
    rates:state.exchangeRates,
    user:state.userData
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getInvestments: getAllInvestmentsAction,
      toggleToast: toggleToastAction,
      toggleModal: toggleModalAction,
      getInvitations: getUserInvitationsAction,
      toggleLoader:toggleOverlayAction
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvitationDetail)
);

