import React from "react";
import Stepper from "../../../tools/components/v1/widgets/Stepper/Stepper";
import UserInfo from "./steps/UserInfo";
import BankInfo from "./steps/BankInfo";
import VerifyBankInfo from "./steps/VerifyBankInfo";
import { useState } from "react";
import {
  faCheckCircle,
  faPiggyBank,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { isEmpty } from "../../../shared/js/utils";
import FormGenerator from "../../../tools/components/v1/FormGenerator/FormGenerator";
import ReactDOM from "react-dom";
import Modal from "../../../tools/components/v1/widgets/Modal/Modal";
import Fields from "../../../tools/components/v1/FormGenerator/Fields";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "../../../shared/js/urls";
import { useDispatch } from "react-redux";
import {
  editMutationAction,
  toggleToastAction,
} from "../../../store/actions/actions";
import { useSelector } from "react-redux";



export default function CreateBankAccount(props) {


  
  const [state, setState] = useState({});
  const [showConformation, setShowConformation] = useState(false);
  let dispatch = useDispatch();
  let user = useSelector((state) => state.userData);
  let wallets = useSelector((state) => state.bankWallets);

  useEffect(() => {
    if (!isEmpty(props?.editData)) {
      setState({
        data: {
          ...state.data,
          ...props.editData,
        },
      });
    }
  }, [props?.editData, state?.data]);

  const collectFormData = (data) => {
    setState({
      data: {
        ...state.data,
        ...data,
      },
    });
  };
  const onNext = (step) => {
    if (step === steps.length - 1) {
      setShowConformation(true);
    }
  };

  const toggleConfirmationModal = () => {
    return ReactDOM.createPortal(
      <Modal
        close={() => setShowConformation(false)}
        themeColor={"var(--app-theme)"}
        size={"medium"}
        title="Account confirmation"
      >
        <FormGenerator
          subtitle=""
          title=""
          elevation={0}
          fields={[
            {
              fieldType: Fields.INPUT,
              label: "Account number",
              name: "account_number",
              placeholder: "Account number",
              required: true,
            },
            {
              fieldType: Fields.INPUT,
              name: "confirm_account_number",
              label: "Confirm Account number",
              placeholder: "Confirm Account number",
              required: true,
            },
          ]}
          onSubmit={(data, reset) => {
            handleSubmit(data, reset);
          }}
          formSubmitBtnText={"Submit"}
        />
      </Modal>,
      document.body
    );
  };
  const updateRedux = (isEdit, data) => {
    dispatch(
      editMutationAction("BANK_WALLETS", wallets || [], isEdit ? data[0] : data)
    );
  };
  const handleSubmit = async (data, reset, isEdit) => {
    if (data.account_number !== data.confirm_account_number) {
      dispatch(
        toggleToastAction({
          show: true,
          message: "Account number and confirm account number do not match",
          type: "failure",
        })
      );
      setShowConformation(false);

      return;
    }
    let toSend = { ...state.data, user: user.id };

    let res;
    toSend.currency = `${toSend?.currency?.name}-${toSend?.currency?.currency?.name}`;
    toSend.country = toSend?.country?.name;
    if (!toSend?.id) {
      res = await ApiCallHandler.send(URLS.CREATE_BANK_WALLETS, "POST", toSend);
    } else {
      res = await ApiCallHandler.send(URLS.UPDATE_BANK_WALLETS, "POST", toSend);
    }
    if (res && res.success) {
      updateRedux(toSend?.id, res?.data);
      setShowConformation(false);
      props.close();
      dispatch(
        toggleToastAction({
          show: true,
          message: !toSend?.id
            ? "Bank Account created successfully"
            : "Bank Account Updated Successfully",
          type: "success",
        })
      );
    } else {
      dispatch(
        toggleToastAction({
          show: true,
          message: "An error occured. Try again",
          type: "failure",
        })
      );
    }
  };

  const steps = [
    {
      name: "Holder's Information",
      component: (
        <UserInfo onStateChange={collectFormData} data={state?.data} />
      ),
      icon: faUser,
    },
    {
      name: "Bank Information",
      component: (
        <BankInfo onStateChange={collectFormData} data={state?.data} />
      ),
      icon: faPiggyBank,
    },
    {
      name: "Verify Information",
      component: (
        <VerifyBankInfo onStateChange={collectFormData} data={state?.data} />
      ),
      icon: faCheckCircle,
    },
  ];
  return (
    <div>
      <Stepper
        variant={"horizontal"}
        steps={steps}
        onNext={onNext}
        state={state}
        submitText={"Confirm"}
      />
      {showConformation && toggleConfirmationModal()}
    </div>
  );
}
